

const HOST = process.env.REACT_APP_BACKEND_HOST;




export const sendReview = async (reviewData) => {
    const { id, username, avatarUrl, message, rating  } = reviewData;

    if (!username) {
        console.error('Brak nazwy użytkownika');
        throw new Error('Brak nazwy użytkownika');
    }

    try {
        const response = await fetch(`${HOST}/api/addReview`, {
            method: 'POST',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({id, username, avatarUrl, message, rating  }), 
        });
        const data = await response.json();
        return data; 
    } catch (error) {
        console.error('Błąd wysyłania recenzji:', error);
        throw error; 
    }
};





export const fetchReviews = async () => {
    try {
        const response = await fetch(`${HOST}/api/getReviews`, {
            method: 'GET',
            mode: "cors",
        });
        const data = await response.json();
        return data.reviews;  
    } catch (error) {
        console.error('Błąd pobierania recenzji:', error);
        throw error;
    }
};




export const fetchUserData = async (username) => {
    const responseUser = await fetch(`${HOST}/api/currentUser?username=${username}`, {
        method: 'GET',
        mode: "cors",
        headers: {
            'Content-Type': 'application/json'
        },
    });

    const dataUser = await responseUser.json();
    if (!responseUser.ok) {
        console.error(`Błąd podczas pobierania informacji o użytkowniku: ${dataUser.error}`);
        throw new Error(dataUser.error || 'Błąd podczas pobierania informacji o użytkowniku');
    }

    return dataUser;
};

export const fetchOrdersData = async (username) => {
    const responseOrders = await fetch(`${HOST}/api/orders?username=${username}`, {
        method: 'GET',
        mode: "cors",
        headers: {
            'Content-Type': 'application/json'
        },
    });



    const dataOrders = await responseOrders.json();
    if (!responseOrders.ok) {
        console.error(`Błąd podczas pobierania zamówień: ${dataOrders.error}`);
        throw new Error(dataOrders.error || 'Błąd podczas pobierania zamówień');
    }

    return dataOrders;
};


export const fetchAllOrdersData = async () => {
    try {
        console.log('Rozpoczynam pobieranie zamówień z API...');

        const responseOrders = await fetch(`${HOST}/api/all-orders`, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            },
        });

        console.log('Status odpowiedzi:', responseOrders.status);
        const responseText = await responseOrders.text(); 
        console.log('Odpowiedź z serwera:', responseText); 
        if (!responseOrders.ok) {
            const errorData = JSON.parse(responseText); 
            console.error(`Błąd podczas pobierania zamówień: ${errorData.error || 'Brak błędu w odpowiedzi'}`);
            throw new Error(errorData.error || 'Błąd podczas pobierania zamówień');
        }

        const dataOrders = JSON.parse(responseText); 
        console.log('Pobrane zamówienia:', dataOrders); 

        return dataOrders; 
    } catch (error) {
        console.error('Wystąpił błąd:', error);
        throw error; 
    }
};




export const sendOrderRequest = async (orderData) => {
    const response = await fetch(`${HOST}/api/order`, {
            method: 'POST',
            mode: "cors",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Błąd podczas składania zamówienia.');
    }

    return response;
};




export const changePassword = async (oldPassword, newPassword) => {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username'); 
    if (!token || !username) {
        console.error('Brak tokenu lub nazwy użytkownika');
        return false;
    }

    try {
        const response = await fetch(`${HOST}/api/change-password`, {
            method: 'POST',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                username, 
                oldPassword,
                newPassword
            })
        });

        if (response.ok) {
            return true; 
        } else {
            const errorData = await response.json();
            console.error('Błąd podczas zmiany hasła:', errorData.error);
            return false; 
        }
    } catch (error) {
        console.error('Wystąpił błąd:', error);
        return false; 
    }
};


export const changeEmail = async (oldEmail, newEmail) => {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username'); 
    if (!token || !username) {
        console.error('Brak tokenu lub nazwy użytkownika');
        return false;
    }

    try {
        const response = await fetch(`${HOST}/api/change-email`, {
            method: 'POST',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                username, 
                oldEmail,
                newEmail
            })
        });

        if (response.ok) {
            return true; 
        } else {
            const errorData = await response.json();
            console.error('Błąd podczas zmiany email:', errorData.error);
            return false; 
        }
    } catch (error) {
        console.error('Wystąpił błąd:', error);
        return false; 
    }
};



export const fetchUsers = async () => {
    try {
        const response = await fetch(`${HOST}/api/allusers`, {
            method: 'GET',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            },
        });

   
        console.log('Status odpowiedzi:', response.status);

        if (!response.ok) {
            const errorData = await response.json(); 
            console.error(`Błąd podczas pobierania użytkowników: ${errorData.error || 'Brak błędu w odpowiedzi'}`);
            throw new Error(errorData.error || 'Błąd podczas pobierania użytkowników'); 
        }

        const data = await response.json(); 
        console.log('Pobrani użytkownicy:', data); 

        return data; 
    } catch (error) {
        console.error('Wystąpił błąd:', error);
        throw error; 
    }
};


export const checkUserRoles = async (username) => {
    try {
        const response = await fetch(`${HOST}/api/user/roles/${username}`);
        const data = await response.json();

        if (response.ok) {
            return {
                mode: "cors",
                roles: data.roles || [],
            };
        } else {
    
            throw new Error(data.error || 'Nieznany błąd');
        }
    } catch (error) {
        console.error('Błąd podczas sprawdzania ról:', error);
        throw error;
    }
};




export const assignRoleToUser = async (username, role) => {
    try {


        const response = await fetch(`${HOST}/api/user/editRole/${username}/${role}`, {
            method: 'POST',
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ role }),
        });
        if (!response.ok) throw new Error('Nie udało się przypisać roli');
        return await response.json();
    } catch (error) {
        console.error('Wystąpił błąd podczas przypisywania roli:', error);
        throw error;
    }
};



export const availableRoles = [
    'wlasciciel',
    'admin',
    'support',
    'user',
];

export const roleButtonMapping = {
    wlasciciel: ['WlascielButton','adminButton', 'supportButton', 'userButton'],
    admin: ['adminButton', 'supportButton', 'userButton'],
    support: ['supportButton', 'userButton'],
    user: ['userButton'],
};


import React from "react";
import Rules from "../components/main/RulesSection";
import Navbar from "../components/navbar/NavbarAdminSection";

function RulesPage() {
    return (
        <main>
            <Navbar />
            <Rules />
        </main>
    );
}

export default RulesPage;

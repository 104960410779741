import { useNavigate } from "react-router-dom";
import { smoothScrollTo } from "./smoothScroll";
import routes from "./routes";

const useCheckCurrentPathAndScroll = () => {
  const navigate = useNavigate();

  const checkCurrentPathAndScroll = (targetId, routeInput) => {
    const currentPath = window.location.pathname;
    const route = routes[routeInput] || routeInput;
    const scrollToTarget = () => smoothScrollTo(targetId);

    if (currentPath === route) {
      scrollToTarget();
    } else {
      navigate(route);
      setTimeout(scrollToTarget, 150);
    }
  };

  return checkCurrentPathAndScroll;
};

export default useCheckCurrentPathAndScroll;

// src/components/ContactSection.js
import React from 'react';

function ContactSection() {
  return (
      <section data-aos="fade-up" id="contact"
               className="container flex items-center justify-center w-full my-20 text-white">
        <div className="flex flex-col text-center">
          <h2 className="text-3xl font-bold mb-5">Social Media!</h2>
          <div className="flex flex-col gap-5 mt-5 lg:flex-row">
            <a className="flex items-center justify-center px-6 py-3 rounded-full text-gray-300 bg-transparent border border-gray-300 hover:text-white transition-colors duration-300"
               href="https://www.youtube.com/@fazedevpl" target="_blank" rel="noopener noreferrer">
              <i className="mr-3 fa-brands fa-youtube text-red-600"></i> {/* YouTube: czerwona */}
              <p>Nasz YouTube</p>
            </a>
            <a className="flex items-center justify-center px-6 py-3 rounded-full text-gray-300 bg-transparent border border-gray-300 hover:text-white transition-colors duration-300"
               href="https://www.tiktok.com/@fazedev.pl?_t=8nzRXmiwztg&_r=1" target="_blank" rel="noopener noreferrer">
              <i className="mr-3 fa-brands fa-tiktok text-black"></i> {/* TikTok: czarna */}
              <p>Nasz TikTok</p>
            </a>
            <a className="flex items-center justify-center px-6 py-3 rounded-full text-gray-300 bg-transparent border border-gray-300 hover:text-white transition-colors duration-300"
               href="https://discord.gg/DQs8rawnZf" target="_blank" rel="noopener noreferrer">
              <i className="mr-3 fa-brands fa-discord text-indigo-500"></i> {/* Discord: niebieska */}
              <p>Nasz Discord</p>
            </a>
          </div>
        </div>
      </section>

  );
}

export default ContactSection;

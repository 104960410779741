
import React from "react";
import Mainstence from "../components/main/MainstenceSection";

function MainstencePage() {
    return (
        <main>
        
            <Mainstence />
        </main>
    );
}

export default MainstencePage;

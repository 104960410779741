import React from 'react';
import logo from '../../img/logo.png';
import useCheckCurrentPathAndScroll from '../../utils/useCheckCurrentPathAndScroll';
import routes from '../../utils/routes';


const Footer = () => {
  const currentYear = new Date().getFullYear(); 
  const checkCurrentPathAndScroll = useCheckCurrentPathAndScroll();

  const handleLinkClick = (event, targetId, route) => {
    event.preventDefault();
    checkCurrentPathAndScroll(targetId, route);
  };


  return (
    <footer
      id="footer"
      className="w-full text-white shadow-lg rounded-t-3xl py-8 aos-init aos-animate"
      data-aos="zoom-in-down"
      data-aos-duration="1000"
    >
      {/* Logo Section */}
      <div className="flex flex-col items-center mb-6">
        <img
          src={logo}
          alt="Logo FazeDev"
          className="w-24 md:w-28 lg:w-32 my-4 rounded-full"
        />
        <p className="text-lg md:text-xl lg:text-2xl font-bold tracking-wide uppercase">
          FazeDev.PL
        </p>
      </div>

    {/* Polityka prywatności i Regulamin */}
<div className="flex justify-center space-x-4 mt-4">
  <a
    href="/privacy-policy"
    className="text-slate-400 hover:text-[#5c14c8] transition"
    onClick={(e) => handleLinkClick(e, 'policy', routes.policy)}
  >
    Polityka prywatności
  </a>
  <a
    href="/terms-of-service"
    className="text-slate-400 hover:text-[#5c14c8] transition"
    onClick={(e) => handleLinkClick(e, 'rules', routes.rules)}
  >
    Regulamin
  </a>
</div>


      {/* Copyright Section */}
      <p className="text-center text-xs md:text-sm lg:text-base text-slate-400 mt-4">
        &copy; {currentYear} | By{' '}
        <a href="#1" className="gradient-text transition-colors hover:text-[#5c14c8]">
          endixon
        </a>{' '}
        for{' '}
        <a href="#1" className="transition-colors hover:text-[#5c14c8]">
          fazedev.pl
        </a>
      </p>
    </footer>
  );
};

export default Footer;

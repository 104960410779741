// src/components/Footer.js
import React from 'react';


function FooterPanelSection() {


    return (
        <footer id="footer" className=" text-white rounded-t-3xl py-4 mx-auto w-4/5 mb-0">
            <p className="py-2 text-center text-slate-400">
                &copy; Copyright {} | By{' '}
                <a href="#31" className="gradient-text transition-colors hover:text-[#5c14c8]">
                endixon
                </a>{' '}
                for{' '}
                <a href="#3" className="transition-colors hover:text-[#5c14c8]">
                    fazedev.pl
                </a>
            </p>
        </footer>   
    );
};

export default FooterPanelSection;
import React, { useState } from 'react';
import logo from '../../img/logo.png';
import useCheckCurrentPathAndScroll from '../../utils/useCheckCurrentPathAndScroll';
import routes from '../../utils/routes';

const NavItem = ({ onClick, children }) => (
    <li>
        <button
            onClick={onClick}
            className="px-4 py-2 transition duration-300 shadow-lg hover:scale-105 rounded-lg"
        >
            {children}
        </button>
    </li>
);

function Navbar() {
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
    const checkCurrentPathAndScroll = useCheckCurrentPathAndScroll();

    const toggleMobileNav = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };

    const handleLinkClick = (event, targetId, route) => {
        event.preventDefault();
        checkCurrentPathAndScroll(targetId, route);
        if (isMobileNavOpen) {
            toggleMobileNav();
        }
    };

    return (
        <div>


<div className="relative">
  <div className="absolute w-[300px] h-[300px]  blur-[180px] right-4 bottom-1/4"></div>
</div>
            <nav className="bg-nav navigation text-white py-6 mx-4 rounded-3xl shadow-lg">
           
                <div className="flex items-center">
                <img src={logo} alt="Logo FazeDev" className="w-16 cursor-pointer lg:w-20 mr-4" />

                    <ul className="hidden lg:flex space-x-5 font-bold text-sm text-[#5c14c8]">
                        <NavItem onClick={(e) => handleLinkClick(e, 'admins', routes.admin)}>Nasz Zespół</NavItem>
                        <NavItem onClick={(e) => handleLinkClick(e, 'contact', routes.home)}>Kontakt</NavItem>
                        <NavItem onClick={(e) => handleLinkClick(e, 'bans', routes.bans)}>Blacklista</NavItem>
                        <NavItem onClick={(e) => handleLinkClick(e, 'reviews', routes.reviews)}>Opinie</NavItem>
                    </ul>
   


                  <button
                        id="toggleNav"
                        onClick={toggleMobileNav}
                        className="px-5 py-2  text-white rounded-lg lg:hidden"
                        >
                        {isMobileNavOpen ? (
                       <span className="text-2xl">&#10005;</span> ) : ( <span className="text-2xl">&#9776;</span>  
                       )}
                </button>
                </div>

                {isMobileNavOpen && (
                    <div className="flex flex-col mt-4 rounded-lg shadow-lg">
                        <ul className="flex flex-col space-y-2 p-4 font-bold text-sm bg-nav text-[#5c14c8]"
                        data-aos="fade-in"  
                        data-aos-duration="300"     
                        data-aos-delay="200"        
                        >

                        <NavItem onClick={(e) => handleLinkClick(e, 'admins', routes.admin)}>Nasz Zespół</NavItem>
                        <NavItem onClick={(e) => handleLinkClick(e, 'contact', routes.home)}>Kontakt</NavItem>
                        <NavItem onClick={(e) => handleLinkClick(e, 'bans', routes.bans)}>Blacklista</NavItem>
                        <NavItem onClick={(e) => handleLinkClick(e, 'reviews', routes.reviews)}>Opinie</NavItem>
                        <NavItem onClick={(e) => handleLinkClick(e, 'offers', routes.home)}>Usługi</NavItem>
                        <NavItem onClick={(e) => handleLinkClick(e, 'what', routes.home)}>Prace</NavItem>
        
                        </ul>
                    </div>
                )}
            </nav>
        </div>
    );
}

export default Navbar;

import React, { useState, useEffect } from 'react';

const CookieNotice = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(false); 
  const [preferences, setPreferences] = useState({
    essential: true,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookieAccepted');
    if (!cookieAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieAccepted', 'true');
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
    setIsVisible(false);
  };

  const handleManagePreferences = () => {
    setIsModalOpen(true);
  };

  const handleSavePreferences = () => {
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
    setIsModalOpen(false);
  };

  const handlePreferenceChange = (type) => {
    setPreferences((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  if (!isVisible) return null;

  return (
    <section
      id="cookie-notice"
      className="fixed max-w-md p-4 mx-auto bg-custom-gradient border border-white dark:bg-gray-800 left-12 bottom-16 dark:border-gray-700 rounded-2xl z-50"
    >
      <h2 className="font-semibold text-gray-800 dark:text-white">🍪 Powiadomienie o Cookies</h2>
      <p className="mt-4 text-sm text-gray-600 dark:text-gray-300">
        Używamy plików cookie, aby zapewnić Ci najlepsze wrażenia na naszej stronie.{' '}
        <a
          href="#"
          className="text-blue-500 hover:underline"
          onClick={(e) => {
            e.preventDefault();
            setIsPolicyModalOpen(true); 
          }}
        >
          Przeczytaj politykę cookies
        </a>.
      </p>
      <div className="flex items-center justify-between mt-4 gap-x-4 shrink-0">
        <button
          onClick={handleManagePreferences}
          className="text-xs text-gray-800 underline transition-colors duration-300 dark:text-white dark:hover:text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          Zarządzaj ustawieniami
        </button>
        <button
          onClick={handleAccept}
          className="text-xs bg-gray-900 font-medium rounded-lg hover:bg-gray-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none"
        >
          Akceptuj
        </button>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-custom-gradient dark:bg-gray-800 p-6 rounded-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Zarządzaj ustawieniami cookies</h3>
            <div className="mt-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={preferences.essential}
                  disabled
                  className="form-checkbox text-blue-500"
                />
                <span className="ml-2 text-sm text-gray-600 dark:text-gray-300">Niezbędne</span>
              </label>
              <label className="flex items-center mt-2">
                <input
                  type="checkbox"
                  checked={preferences.analytics}
                  onChange={() => handlePreferenceChange('analytics')}
                  className="form-checkbox text-blue-500"
                />
                <span className="ml-2 text-sm text-gray-600 dark:text-gray-300">Analityczne</span>
              </label>
              <label className="flex items-center mt-2">
                <input
                  type="checkbox"
                  checked={preferences.marketing}
                  onChange={() => handlePreferenceChange('marketing')}
                  className="form-checkbox text-blue-500"
                />
                <span className="ml-2 text-sm text-gray-600 dark:text-gray-300">Marketingowe</span>
              </label>
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setIsModalOpen(false)}
                className="text-xs text-gray-800 underline transition-colors duration-300 dark:text-white dark:hover:text-gray-400 hover:text-gray-600 focus:outline-none mr-4"
              >
                Anuluj
              </button>
              <button
                onClick={handleSavePreferences}
                className="text-xs bg-blue-500 font-medium rounded-lg hover:bg-blue-700 text-white px-4 py-2.5 duration-300 transition-colors focus:outline-none"
              >
                Zapisz ustawienia
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal polityki cookies */}
   {/* Modal polityki cookies */}
{isPolicyModalOpen && (
  <div className="fixed inset-0 z-60 flex items-center justify-center bg-black bg-opacity-70">
    <div className="bg-custom-gradient dark:bg-gray-800 p-8 rounded-lg max-w-lg w-full relative overflow-auto max-h-[80vh]">
      <button
        onClick={() => setIsPolicyModalOpen(false)}
        className="absolute top-2 right-2 text-gray-800 dark:text-white text-lg"
      >
        &times;
      </button>
      <h3 className="text-2xl font-semibold text-gray-800 dark:text-white">Polityka Cookies</h3>
      <p className="mt-4 text-sm text-gray-600 dark:text-gray-300">
        <strong>1. Czym są pliki cookies?</strong><br />
        Pliki cookies to niewielkie pliki tekstowe zapisywane na urządzeniu użytkownika podczas przeglądania stron internetowych. Służą do przechowywania informacji o sesji użytkownika, co pozwala na lepsze dostosowanie funkcjonalności strony do jego potrzeb.<br /><br />

        <strong>2. Jakie rodzaje plików cookies wykorzystujemy?</strong><br />
        Nasza strona korzysta z różnych typów plików cookies:<br />
        - <strong>Niezbędne cookies</strong>: Te pliki są konieczne do prawidłowego działania strony. Umożliwiają poruszanie się po stronie i korzystanie z jej podstawowych funkcji.<br />
        - <strong>Cookies analityczne</strong>: Zbierają informacje o sposobie korzystania ze strony przez użytkowników, co pomaga nam usprawniać jej działanie. Dane te są anonimowe.<br />
        - <strong>Cookies marketingowe</strong>: Pozwalają na personalizowanie treści reklamowych wyświetlanych użytkownikowi, dzięki czemu są bardziej dopasowane do jego zainteresowań.<br /><br />

        <strong>3. Jak długo przechowujemy dane?</strong><br />
        Pliki cookies są przechowywane na Twoim urządzeniu przez różny czas, w zależności od rodzaju plików:<br />
        - <strong>Cookies sesyjne</strong>: Usuwane po zamknięciu przeglądarki.<br />
        - <strong>Cookies stałe</strong>: Pozostają na urządzeniu przez określony czas lub do momentu ich usunięcia przez użytkownika.<br /><br />

        <strong>4. Jak zarządzać plikami cookies?</strong><br />
        Użytkownik ma możliwość zarządzania plikami cookies poprzez ustawienia przeglądarki. Może również w każdej chwili usunąć zapisane pliki cookies. Należy jednak pamiętać, że zablokowanie niektórych rodzajów plików cookies może negatywnie wpłynąć na funkcjonowanie strony.<br /><br />

        <strong>5. Zmiany w polityce cookies</strong><br />
        Możemy wprowadzać zmiany w niniejszej polityce cookies, aby zapewnić zgodność z obowiązującymi przepisami oraz lepiej odpowiadać na potrzeby użytkowników. Wszelkie zmiany zostaną opublikowane na tej stronie.<br /><br />

        <strong>6. Kontakt</strong><br />
        W przypadku pytań dotyczących naszej polityki cookies prosimy o kontakt na adres: suppport@fazedev.pl
      </p>
    </div>
  </div>
)}

    </section>
  );
};

export default CookieNotice;

import React, {  } from 'react';

function MainstenceSection() {


    return (
        <div className="flex flex-col items-center mb-56 mt-56">

            <h1 className="text-4xl font-bold text-[#821dff] mb-8">FazeDev.pl</h1>

            <div
                className="mainstence-section p-8 rounded-lg shadow-lg text-white transition-transform transform hover:scale-105"
                data-aos="fade-up"
            >
          <h2 className="text-3xl font-extrabold mb-6 text-center animate-pulse">Strona w trakcie konserwacji!</h2>
                <p className="text-lg mb-4 text-center animate-pulse">Przygotowujemy sezon 2, który wystartuje w 2025 roku!</p>
                <p className="text-lg text-center animate-pulse">Przepraszamy za niedogodności i dziękujemy za cierpliwość!</p>
            </div>
        </div>
    );
}

export default MainstenceSection;

import { useEffect } from 'react';

const Redirect = () => {
    useEffect(() => {
      
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const username = urlParams.get('username');

        if (token && username) {
            localStorage.setItem('token', token);
            localStorage.setItem('username', username);
            window.location.href = '/panel/dashboard';
        } else {
            console.error('Brak tokena lub username w URL');
        }
    }, []); 

    return null; 
};

export default Redirect;

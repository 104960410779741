// src/pages/AdminsPage.js
import React from "react";
import TeamSection from "../../components/main/TeamSection";
import ContactSection from "../../components/main/ContactSection";
import Navbar from "../../components/navbar/NavbarAdminSection";


function AdminsPage() {
  return (
    <main>
      <Navbar />
      <TeamSection />
      <ContactSection />
    </main>
  );
}

export default AdminsPage;

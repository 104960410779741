// src/utils/routes.js

const routes = {
  home: "/",
  admin: "/admins",
  bans: "/bans",
  services: "/services",
  works: "/works",
  contact: "/contact",
  panel: "/panel",
  panellogin: "/panel/login",
  panelregister: "/panel/register",
  dashboard: "/panel/dashboard",
  policy: "/policy",
  rules: "/rules",
  reviews: "/panel/dashboard/reviews",
  
  
};

export default routes;

// src/pages/IndexPage.js
import React from "react";
import Register from "../../components/panel/PanelSection";
import Navbar from "../../components/navbar/NavbarAdminSection";

function PanelPage() {
    return (
        <main>
            <Navbar />
            <Register />
        </main>
    );
}

export default PanelPage;

import React, { useState, useEffect } from 'react';
import useCheckCurrentPathAndScroll from '../../utils/useCheckCurrentPathAndScroll';
import routes from '../../utils/routes';
import imgLogo from '../../img/logo.png';
import { ShieldExclamationIcon } from '@heroicons/react/24/solid';

function Slider() {
    const checkCurrentPathAndScroll = useCheckCurrentPathAndScroll();
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [messages, setMessages] = useState([]);
    const PORT = process.env.REACT_APP_BACKEND_PORT;
    const HOST = process.env.REACT_APP_BACKEND_HOST;
    const currentMessage = messages.length > 0 ? messages[currentMessageIndex].message : "";
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const handleLinkClick = (event, targetId, route) => {
        event.preventDefault();
        checkCurrentPathAndScroll(targetId, route);
    };
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const fetchAlertMessages = async () => {
            try {
                const response = await fetch(`${HOST}/api/infoweb/messages`, {
                    method: 'GET',
                    mode: 'cors', 
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                
                const data = await response.json();
                if (response.ok && data.messages.length > 0) {
                    setMessages(data.messages);
                    setCurrentMessageIndex(0);
                } else {
                    setMessages([]);
                }
            } catch (error) {
                setMessages([]);
            }
        };

        fetchAlertMessages();

        const intervalId = setInterval(() => {
            setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 30000);
    
        return () => clearInterval(intervalId);
    }, [messages.length, HOST, PORT]);

    return (
        <section id="slider" className="slider relative h-[80vh] flex flex-col items-center justify-center text-white mt-20 bg-gradient-to-r from-indigo-900 to-indigo-700">
            {/* Informational Message */}
            {currentMessage && !isMobile && (
                <div className="absolute top-0 mt-4 left-0 w-full flex justify-center z-20">
                    <div className="flex items-center justify-between bg-purple-700 text-white rounded-full p-3 shadow-lg max-w-3xl w-full">
                        <span className="flex items-center font-bold text-sm uppercase px-3 py-1 bg-purple-800 rounded-full">
                            <ShieldExclamationIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                            INFORMACJA:
                        </span>
                        <span className="ml-2 font-semibold">{currentMessage}</span>
                    </div>
                </div>
            )}

            {/* Desktop Version */}
            {!isMobile && (
                <div className="relative flex flex-col items-center justify-between container mx-auto px-8 sm:px-16 lg:px-24">
                    <div className="flex flex-col lg:flex-row gap-12 items-center justify-center lg:justify-between mt-16">
                        <div className="flex flex-col gap-8 max-w-lg">
                            <h5 className="text-xl font-bold text-[#821dff]">FAZEDEV.PL</h5>
                            <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold leading-tight text-white">
                                Zbuduj swoją przyszłość w sieci z FazeDev.PL już dziś!
                            </h1>
                            <p className="text-sm lg:text-lg text-neutral-300 font-light mt-4">
                                FazeDev to specjalistyczne usługi, które realizujemy na zamówienie. Tworzymy wszystko, co potrzebne do Twojego projektu: od serwerów Discord i botów, przez unikalne strony internetowe, po grafiki i animacje, które przyciągną uwagę...
                            </p>
                            <div className="flex gap-6 mt-6">
                                <button
                                    onClick={(e) => handleLinkClick(e, 'panel', routes.panel)}
                                    className="slider-button px-8 py-3 text-xl text-white bg-[#5c14c8] rounded-full font-semibold transition-transform duration-300 hover:scale-105 hover:bg-[#3c0b6a] shadow-xl"
                                >
                                    Panel
                                </button>
                                <button
                                    onClick={(e) => handleLinkClick(e, 'offers', routes.home)}
                                    className="slider-button px-8 py-3 text-xl text-white bg-[#5c14c8] rounded-full font-semibold transition-transform duration-300 hover:scale-105 hover:bg-[#5512ba] shadow-xl"
                                >
                                    Nasze Usługi
                                </button>
                            </div>
                        </div>
                        <img className="w-full sm:w-[600px] lg:w-[700px] object-cover mt-8" src={imgLogo} alt="FazeDev Logo" />
                    </div>
                </div>
            )}

            {/* Mobile Version */}
            {isMobile && (
                <div className="relative flex flex-col lg:flex-row items-center justify-between mx-4 sm:mx-8 lg:mx-16 py-10">
                    <div className="flex flex-col gap-6 text-center lg:text-left w-full">
                        <h5 className="text-xl font-bold text-[#821dff]">FAZEDEV.PL</h5>
                        <h1 className="text-3xl sm:text-4xl font-bold text-white">
                            Zbuduj swoją przyszłość w sieci z FazeDev.PL już dziś!
                        </h1>
                        <p className="text-sm lg:text-lg text-neutral-300 font-light mt-4">
                            FazeDev to specjalistyczne usługi, które realizujemy na zamówienie. Tworzymy wszystko, co potrzebne do Twojego projektu: od serwerów Discord i botów, przez unikalne strony internetowe, po grafiki i animacje, które przyciągną uwagę...
                        </p>
                        <div className="flex flex-col sm:flex-row gap-4 mt-6 justify-center lg:justify-start">
                            <button
                                onClick={(e) => handleLinkClick(e, 'panel', routes.panel)}
                                className="slider-button py-3 px-6 sm:px-8 text-lg sm:text-xl text-white bg-[#5c14c8] rounded-full font-semibold transition-transform duration-300 hover:scale-105 hover:bg-[#3c0b6a] shadow-lg"
                            >
                                Panel
                            </button>
                            <button
                                onClick={(e) => handleLinkClick(e, 'offers', routes.home)}
                                className="slider-button py-3 px-6 sm:px-8 text-lg sm:text-xl text-white bg-[#5c14c8] rounded-full font-semibold transition-transform duration-300 hover:scale-105 hover:bg-[#5512ba] shadow-lg"
                            >
                                Nasze Usługi
                            </button>
                        </div>
                    </div>
                    <img className="w-full sm:w-[600px] lg:w-[500px] object-cover mt-8" src={imgLogo} alt="FazeDev Logo" />
                </div>
            )}
        </section>
    );
}

export default Slider;

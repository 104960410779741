// src/utils/smoothScroll.js

export const smoothScrollTo = (targetId) => {
  const element = document.getElementById(targetId);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

import { useState } from 'react';

export const useInitial = () => {
    const [status, setStatus] = useState({
        loading: false,
        error: false
    });

    // Przykład użycia funkcji setStatus
    const startAsyncOperation = async () => {
        try {
            setStatus({ loading: true, error: false });

            // Symulacja operacji asynchronicznej, np. fetch
            await new Promise((resolve) => setTimeout(resolve, 2000));

            // Po zakończeniu operacji
            setStatus({ loading: false, error: false });
        } catch (e) {
            setStatus({ loading: false, error: true });
        }
    };

    // Uruchamiamy operację asynchroniczną
    startAsyncOperation();

    return status;
};

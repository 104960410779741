// src/components/ServiceSection.js
import React, { useState } from 'react';


function ServiceSection() {
  const [showHiddenServices, setShowHiddenServices] = useState(false);

  const toggleServices = () => {
    setShowHiddenServices(!showHiddenServices);
  };

  return (
      <>
      <section
          data-aos="fade-up"
          data-aos-delay="150"
          id="offers"
          className="container mx-auto my-20 text-white ">

        <div className="text-center flex justify-center">
          <h2 className="text-3xl font-bold custom-margin">
            Nasze Usługi <span className=""> </span>

        </h2>
        </div>

        <div
            className="mt-10 max-w-sm mx-auto grid gap-6 lg:grid-cols-3 items-start lg:max-w-none group scale-[0.85]"
            data-spotlight="">

          <div
              className="service relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
            <div
                className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
              <div
                  className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                  aria-hidden="true">
                <div
                    className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
              </div>
              <div className="flex flex-col h-full items-center text-center">
                <div className="relative inline-flex">
                  <div
                      className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                      aria-hidden="true"></div>
                  <i className="fab fa-discord text-transparent bg-gradient-to-r from-[#2594e9] to-[#1c7bb4] bg-clip-text mr-2 text-5xl animate-pulse"></i>

                </div>
                <div className="grow mb-5">
                  <h2 className="text-xl text-slate-200 font-bold mb-8">Serwer Discord</h2>
                  <p className="text-sm text-slate-500">Wykonamy dla Ciebie serwer
                    Discord,</p>
                  <p className="text-sm text-slate-500">dopasowany do Twoich potrzeb i wymagań</p>
                </div>
                <p className="text-xs text-slate-200 font-bold mb-3">Przykładowe realizacje
                  dostępne na naszym Discordzie</p>

                <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                   
                   href="https://dc.fazedev.pl"
                   target="_blank"
                   rel="noopener noreferrer"
                >
                  <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                       width="16" height="14">
                    <path
                        d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                  </svg>
                  <span>Kliknij aby zobaczyc</span>
                </a>
              </div>
            </div>
          </div>

          {/* Karta usługi n2 - Strona internetowa */}
          <div
              className="service relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">            <div
                className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
              <div
                  className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                  aria-hidden="true">
                <div
                    className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
              </div>
            <div className="flex flex-col h-full items-center text-center">
              <div className="relative inline-flex">
                <div
                    className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                    aria-hidden="true"></div>
                <i className="fas fa-robot text-transparent bg-gradient-to-r from-[#7289da] via-[#5f7bec] to-[#3e5bc3] bg-clip-text mr-2 text-5xl animate-pulse"></i>
              </div>
              <div className="grow mb-5">
                <h2 className="text-xl text-slate-200 font-bold mb-3">Bot Discord</h2>
                <p className="text-sm text-slate-500">Stworzymy dla Ciebie dowolnego bota
                  Discord,</p>
                <p className="text-sm text-slate-500">który będzie dostosowany</p>
                <p className="text-sm text-slate-500">do Twoich potrzeb
                  i wymagań.</p>
              </div>

              <p className="text-xs text-slate-200 font-bold mb-3">Przykładowe realizacje
                dostępne na naszym Discordzie</p>

              <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                 href="https://dc.fazedev.pl"
                 target="_blank"
                 rel="noopener noreferrer"
              >
                <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                     width="16" height="14">
                  <path
                      d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                </svg>
                <span>Kliknij aby zobaczyc</span>
              </a>
            </div>
          </div>
          </div>

          {/* Karta usługi n2 - Strona internetowa */}
          <div
              className="service relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
            <div
                className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
              <div
                  className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                  aria-hidden="true">
                <div
                    className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
              </div>
              <div className="flex flex-col h-full items-center text-center">
                <div className="relative inline-flex">
                  <div
                      className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                      aria-hidden="true"></div>
                  <i className="fas fa-globe text-transparent bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-700 bg-clip-text mr-2 text-5xl animate-pulse"></i>
                </div>
                <div className="grow mb-5">
                  <h2 className="text-xl text-slate-200 font-bold mb-8">Strona
                    Internetowa</h2>
                  <p className="text-sm text-slate-500">Stworzymy dla Ciebie unikalną stronę internetową,</p>
                  <p className="text-sm text-slate-500"> dopasowaną do Twoich potrzeb i wymagań.</p>
                </div>

                <p className="text-xs text-slate-200 font-bold mb-3">Przykładowe realizacje
                  dostępne na naszym Discordzie</p>


                <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                   href="https://dc.fazedev.pl"
                   target="_blank"
                   rel="noopener noreferrer"
                >
                  <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                       width="16" height="14">
                    <path
                        d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                  </svg>
                  <span>Kliknij aby zobaczyc</span>
                </a>
              </div>
            </div>
          </div>


          {/* Karta usługi n2 - Strona internetowa */}
          <div
              className="service relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
            <div
                className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
              <div
                  className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                  aria-hidden="true">
                <div
                    className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
              </div>
              <div className="flex flex-col h-full items-center text-center">
                <div className="relative inline-flex">
                  <div
                      className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                      aria-hidden="true"></div>
                  <i className="fas fa-image text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text mr-2 text-5xl animate-pulse"></i>


                </div>
                <div className="grow mb-5">
                  <h2 className="text-xl text-slate-200 font-bold mb-3">Grafiki</h2>
                  <p className="text-sm text-slate-500">Stworzymy dla Ciebie unikalne
                    grafiki,</p>
                  <p className="text-sm text-slate-500">które wyróżnią Twoją markę i
                    przyciągną
                    uwagę.</p>
                </div>

                <p className="text-xs text-slate-200 font-bold mb-3">Przykładowe realizacje
                  dostępne na naszym Discordzie</p>

                <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                   href="https://dc.fazedev.pl"
                   target="_blank"
                   rel="noopener noreferrer"
                >
                  <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                       width="16" height="14">
                    <path
                        d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                  </svg>
                  <span>Kliknij aby zobaczyc</span>
                </a>
              </div>
            </div>
          </div>


          {/* Karta usługi n2 - Strona internetowa */}
          <div
              className="service relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
            <div
                className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
              <div
                  className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                  aria-hidden="true">
                <div
                    className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
              </div>
              <div className="flex flex-col h-full items-center text-center">
                <div className="relative inline-flex">
                  <div
                      className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                      aria-hidden="true"></div>
                  <i className="fas fa-film text-transparent bg-gradient-to-r from-red-400 via-yellow-400 to-purple-600 bg-clip-text mr-2 text-5xl animate-pulse "></i>

                </div>
                <div className="grow mb-5">
                  <h2 className="text-xl text-slate-200 font-bold mb-3">Montaż Filmów</h2>
                  <p className="text-sm text-slate-500">Zmontujemy film dla Ciebie,</p>
                  <p className="text-sm text-slate-500">dopasowany do Twoich oczekiwań.</p>
                </div>

                <p className="text-xs text-slate-200 font-bold mb-3">Przykładowe realizacje
                  dostępne na naszym Discordzie</p>

                <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                   href="https://dc.fazedev.pl"
                   target="_blank"
                   rel="noopener noreferrer"
                >
                  <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                       width="16" height="14">
                    <path
                        d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                  </svg>
                  <span>Kliknij aby zobaczyc</span>
                </a>
              </div>
            </div>
          </div>


          {/* Karta usługi n2 - Strona internetowa */}
          <div
              className="service relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
            <div
                className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
              <div
                  className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                  aria-hidden="true">
                <div
                    className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
              </div>
              <div className="flex flex-col h-full items-center text-center">
                <div className="relative inline-flex">
                  <div
                      className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                      aria-hidden="true"></div>
                  <i className="fas fa-cube text-transparent bg-gradient-to-r from-green-500 via-green-700 to-brown-500 bg-clip-text mr-2 text-5xl animate-pulse"></i>


                </div>
                <div className="grow mb-5">
                  <h2 className="text-xl text-slate-200 font-bold mb-3">Trailery
                    Minecraft </h2>
                  <p className="text-sm text-slate-500">Stworzymy dla Ciebie unikalny trailer
                    Minecraft,</p>
                  <p className="text-sm text-slate-500">który przyciągnie uwagę Twojej
                    społeczności.</p>
                </div>

                <p className="text-xs text-slate-200 font-bold mb-3">Przykładowe realizacje
                  dostępne na naszym Discordzie</p>

                <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                   href="https://dc.fazedev.pl"
                   target="_blank"
                   rel="noopener noreferrer"
                >
                  <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                       width="16" height="14">
                    <path
                        d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                  </svg>
                  <span>Kliknij aby zobaczyc</span>
                </a>
              </div>
            </div>
          </div>


          {showHiddenServices && (
              <>

                {/* Karta usługi n2 - Strona internetowa */}
              <div
                  className="service relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
                <div
                    className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
                  <div
                      className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                      aria-hidden="true">
                    <div
                        className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                  </div>
                  <div className="flex flex-col h-full items-center text-center">
                    <div className="relative inline-flex">
                      <div
                          className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                          aria-hidden="true"></div>
                      <i className="fas fa-clapperboard text-transparent bg-gradient-to-r from-red-400 via-yellow-400 to-green-500 bg-clip-text mr-2 text-5xl animate-pulse"></i>


                    </div>
                    <div className="grow mb-5">
                      <h2 className="text-xl text-slate-200 font-bold mb-3">Animacje</h2>
                      <p className="text-sm text-slate-500">Stworzymy dla Ciebie efektowne
                        animacje,</p>
                      <p className="text-sm text-slate-500">które ożywią Twoje projekty w
                        Minecraft.</p>
                    </div>

                    <p className="text-xs text-slate-200 font-bold mb-3">Przykładowe realizacje
                      dostępne na naszym Discordzie</p>

                    <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                       href="https://dc.fazedev.pl"
                       target="_blank"
                       rel="noopener noreferrer"
                    >
                      <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                           width="16" height="14">
                        <path
                            d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                      </svg>
                      <span>Kliknij aby zobaczyc</span>
                    </a>
                  </div>
                </div>
              </div>

                {/* Karta usługi n2 - Strona internetowa */}
                <div
                    className="service relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
                <div
                    className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
                  <div
                      className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                      aria-hidden="true">
                    <div
                        className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                  </div>
                  <div className="flex flex-col h-full items-center text-center">
                    <div className="relative inline-flex">
                      <div
                          className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                          aria-hidden="true"></div>
                      <i className="fas fa-plug text-transparent bg-gradient-to-r from-orange-400 via-orange-600 to-orange-800 bg-clip-text mr-2 text-5xl animate-pulse"></i>


                    </div>
                    <div className="grow mb-5">
                      <h2 className="text-xl text-slate-200 font-bold mb-3">Plugin Minecraft</h2>
                      <p className="text-sm text-slate-500">Oferujemy stworzenie dostosowanego
                        pluginu,</p>
                      <p className="text-sm text-slate-500">który wzbogaci Twoją rozgrywkę.</p>
                    </div>

                    <p className="text-xs text-slate-200 font-bold mb-3">Przykładowe realizacje
                      dostępne na naszym Discordzie</p>

                    <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                       href="https://dc.fazedev.pl"
                       target="_blank"
                       rel="noopener noreferrer"
                    >
                      <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                           width="16" height="14">
                        <path
                            d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                      </svg>
                      <span>Kliknij aby zobaczyc</span>
                    </a>
                  </div>
                </div>
                </div>

                {/* Karta usługi n2 - Strona internetowa */}
                <div
                    className="service relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
                <div
                    className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
                  <div
                      className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                      aria-hidden="true">
                    <div
                        className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                  </div>
                  <div className="flex flex-col h-full items-center text-center">
                    <div className="relative inline-flex">
                      <div
                          className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                          aria-hidden="true"></div>
                      <i className="fas fa-archive text-transparent bg-gradient-to-r from-red-400 via-red-600 to-red-800 bg-clip-text mr-2 text-5xl animate-pulse"></i>


                    </div>
                    <div className="grow mb-5">
                      <h2 className="text-xl text-slate-200 font-bold mb-3">Paczki Minecraft</h2>
                      <p className="text-sm text-slate-500">Zaprojektujemy paczki Minecraft,</p>
                      <p className="text-sm text-slate-500">które dostosują Twoje doświadczenie w
                        grze.</p>
                    </div>

                    <p className="text-xs text-slate-200 font-bold mb-3">Przykładowe realizacje
                      dostępne na naszym Discordzie</p>

                    <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                       href="https://dc.fazedev.pl"
                       target="_blank"
                       rel="noopener noreferrer"
                    >
                      <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                           width="16" height="14">
                        <path
                            d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                      </svg>
                      <span>Kliknij aby zobaczyc</span>
                    </a>
                  </div>
                </div>
                </div>


                {/* Karta usługi n2 - Strona internetowa */}
                <div
                    className="service relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
                <div
                    className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
                  <div
                      className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                      aria-hidden="true">
                    <div
                        className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                  </div>
                  <div className="flex flex-col h-full items-center text-center">
                    <div className="relative inline-flex">
                      <div
                          className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                          aria-hidden="true"></div>
                      <i className="fas fa-axe text-transparent bg-gradient-to-r from-[#A0522D] via-[#CD853F] to-[#D2B48C] bg-clip-text mr-2 text-5xl animate-pulse"></i>


                    </div>
                    <div className="grow mb-5">
                      <h2 className="text-xl text-slate-200 font-bold mb-3">Budowle Minecraft</h2>
                      <p className="text-sm text-slate-500">Zrealizujemy dla Ciebie unikalne
                        budowle,</p>
                      <p className="text-sm text-slate-500">które wyróżnią Twój świat w
                        Minecraft.</p>
                    </div>

                    <p className="text-xs text-slate-200 font-bold mb-3">Przykładowe realizacje
                      dostępne na naszym Discordzie</p>

                    <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                       href="https://dc.fazedev.pl"
                       target="_blank"
                       rel="noopener noreferrer"
                    >
                      <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                           width="16" height="14">
                        <path
                            d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                      </svg>
                      <span>Kliknij aby zobaczyc</span>
                    </a>
                  </div>
                </div>
                </div>
              </>
          )}
        </div>
      </section>

        <section className="container mx-auto my-20 text-white">
            <div className="flex justify-center mt-6 w-full">
              <button
                  id="toggle-Service"
                  className="text-white  p-4 rounded-full ransition-all flex items-center justify-center"
                  onClick={toggleServices}>
                <i id="arrow-icon"
                   className={`fa-solid ${showHiddenServices ? 'fa-arrow-up' : 'fa-arrow-down'} text-3xl transition-transform duration-300`}></i>

              </button>
            </div>
        </section>
      </>

  );
}

export default ServiceSection;



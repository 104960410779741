import React, { useState, useEffect } from 'react';
import { sendReview,   fetchReviews } from '../api/backendAPI';
import useCheckCurrentPathAndScroll from "../../utils/useCheckCurrentPathAndScroll";
import routes from "../../utils/routes";

const ReviewsSection = () => {
        /* eslint-disable react-hooks/exhaustive-deps */
    const [opinion, setOpinion] = useState('');
    const [reviews, setReviews] = useState([]);
    const [response, setResponse] = useState(null);
    const [rating, setRating] = useState(0); 
    const username = localStorage.getItem('username');
    const HOST = process.env.REACT_APP_BACKEND_HOST;

    const checkCurrentPathAndScroll = useCheckCurrentPathAndScroll();
    const [avatarUrl, setAvatarUrl] = useState('');
   
    const [currentPage, setCurrentPage] = useState(1);
    const reviewsPerPage = 4; 
    const totalPages = Math.ceil(reviews.length / reviewsPerPage);
    const indexOfLastReview = currentPage * reviewsPerPage;
    const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
    const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
  

    const loadReviews = async () => {
        try {
            const fetchedReviews = await fetchReviews();
            setReviews(fetchedReviews);
        } catch (error) {
            setResponse('Błąd ładowania opinii.');
        }
    };

       const handlePageChange = (page) => {
        setCurrentPage(page);
    };



    function generateRandomId(length = 8) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    useEffect(() => {
        loadReviews();
    }, []);

    useEffect(() => {
        const checkLoginStatus = async () => {
            const username = localStorage.getItem('username');
            if (!username) {
                setIsLoggedIn(false);  
                setErrorMessage('Musisz być zalogowany, aby wystawić opinię. W panelu kliknij w zakładkę "Opinie", aby wrócić do sekcji opinii');

            } else {
 
                await fetchAvatar(username);
                setIsLoggedIn(true);  
                setErrorMessage(''); 
            }
        };


        const fetchAvatar = async (username) => {
            if (username) {
                try {
                    const response = await fetch(`${HOST}/api/get-avatar/${username}`, {
                        method: 'GET',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Błąd HTTP: ${response.status}`);
                    }

                    const data = await response.json();
                    setAvatarUrl(data.avatarUrl);
                } catch (error) {
                    console.error('Błąd przy pobieraniu awatara użytkownika:', error);
                }
            } else {
                console.warn('Brak nazwy użytkownika w localStorage.');
            }
        };

        checkLoginStatus().catch(console.error);
    }, []);




    const handleSendReview = async () => {
        if (!username || !opinion) {
            setResponse('Proszę wprowadzić zarówno nick, jak i opinię.');
            return;
        }

        try {
            const reviewData = {
                id: generateRandomId(8), 
                username,
                avatarUrl,
                message: opinion,
                rating 
            };

            const res = await sendReview(reviewData);
            setResponse(res.message);
            setOpinion('');
            setRating(0); 
            loadReviews();
            setIsModalOpen(false)
            setReviews(prevReviews => [{
                username: reviewData.username,
                avatar: reviewData.avatarUrl,
                message: reviewData.message,
                rating: reviewData.rating
            }, ...prevReviews]); 
            
        } catch (error) {
            setResponse('Błąd wysyłania opinii.');
        }
    };



    return (
      
            <div className="min-h-screen bg-custom-panel p-6">
    <div className="max-w-6xl mx-auto">
    <h1 className="text-center text-3xl font-bold text-white mb-8">
    Podziel się swoją opinią
</h1>



     
            {isModalOpen && (
         
                <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-lg flex items-center justify-center z-50">
                    <div className="flex flex-col items-center justify-center mx-auto mt-20 mb-5 text-center bg-gradient-to-r from-[#18181a] to-[#101010] p-6 rounded-lg shadow-lg w-[800px] max-w-2xl transition-transform transform hover:scale-105">
                    <h2 className="text-white text-2xl font-semibold">Twoja opinia ma znaczenie!</h2>
                 <p className="text-white mt-4">Prosimy o ocenę naszej usługi. Twoje zdanie pomoże nam dostarczać lepsze doświadczenia dla Ciebie i innych użytkowników.</p>

                        <button
                            onClick={() => setIsModalOpen(false)}
                            className="absolute top-2 right-2 text-gray-600 text-2xl"
                        >
                            &times;
                        </button>

                        <textarea
                            value={opinion}
                            onChange={(e) => setOpinion(e.target.value)}
                            placeholder="Napisz swoją opinię tutaj..."
                            className="w-full mt-4 p-4 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 mb-4 transition-all duration-200"
                        />

                     
                        <div className="flex justify-center mb-4">
                            {[1, 2, 3, 4, 5].map((star) => (
                                <span
                                    key={star}
                                    onClick={() => setRating(star)}
                                    className={`cursor-pointer text-2xl ${star <= rating ? 'text-yellow-500' : 'text-gray-400'} transition-colors duration-200`}
                                >
                                    ★
                                </span>
                            ))}
                        </div>

  
                        <button
                            onClick={handleSendReview}
                            className="w-full py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg  focus:outline-none transform hover:scale-105 transition duration-200"
                        >
                            Wyślij opinię
                        </button>
                    </div>
                    </div>
            )}


            {response && (
                <div className="text-center mt-4 text-lg text-gray-700 font-medium">
                    {response}
                </div>
            )}

<h3 className="text-2xl font-semibold mt-8 mb-6 text-center text-white">Wszystkie opinie:</h3>
<div className="space-y-6">
    {currentReviews.map((review, index) => (
        <div
            key={index}
            className="bg-gradient-to-r from-[#18181a] to-[#101010] rounded-lg shadow-md hover:shadow-lg transition-all duration-300 p-6"
        >
            <div className="flex items-center">
             
                <img
                    src={review.avatar}
                    alt="avatar"
                    className="w-12 h-12 rounded-full object-cover"  
                />

            
                <div className="ml-4 flex items-center">
           
                    <div className="font-semibold text-lg text-bold text-white mr-2">{review.username}</div>
                    
                
                    <div className="flex">
                        {[1, 2, 3, 4, 5].map((star) => (
                            <span
                                key={star}
                                className={`text-2xl ${star <= review.rating ? 'text-yellow-500' : 'text-gray-400'}`}
                            >
                                ★
                            </span>
                        ))}
                    </div>
                </div>
            </div>


           
            <p className="text-gray-600 mt-2">{review.message}</p>
        </div>
    ))}
</div>




           
            <div className="flex justify-center mt-6">
                <button
                    className="px-4 py-2 bg-gray-700 text-white rounded-md mx-2"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    {'<'}
                </button>
                {[...Array(totalPages)].map((_, index) => (
                    <button
                        key={index}
                        className={`px-4 py-2 mx-1 rounded-md ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-700 text-white'}`}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
                <button
                    className="px-4 py-2 bg-gray-700 text-white rounded-md mx-2"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    {'>'}
                </button>
            </div>


            {!isLoggedIn && errorMessage && (
  <div className="text-center py-4 lg:px-4">
    <div className="p-2 bg-red-800 items-center text-red-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
      <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">Error</span>
      <span className="font-semibold mr-2 text-left flex-auto">{errorMessage}</span>
      <svg class="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
    </div>
  </div>
)}




            <div className="mt-4 text-center gap-2 flex justify-center items-center">
            <button
    onClick={() => checkCurrentPathAndScroll(isLoggedIn ? 'dashboard' : 'panel', isLoggedIn ? routes.dashboard : routes.panel)}
    className="px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none transition-all duration-200 mb-4"
>
    {isLoggedIn ? 'Wróć do panelu' : 'Przejdź do panelu'}
</button>



                    <button
                onClick={() => {
                    if (isLoggedIn) {
                        setIsModalOpen(true); 
                    }
                }}
                className={`px-6 py-3 rounded-lg text-white focus:outline-none transition-all duration-200 mb-4 ${isLoggedIn ? 'bg-blue-600 hover:bg-blue-700' : 'bg-red-600 cursor-not-allowed'}`}
                disabled={!isLoggedIn} 
            >
                Wyślij opinię
            </button>

            </div>
        </div>
        </div>
    );
};

export default ReviewsSection;

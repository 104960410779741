import React from 'react';

function RulesSection() {
    return (
        
            <div className="container mx-auto max-w-4xl bg-custom-panel bg-opacity-90 p-8  rounded-lg shadow-lg mt-20 ">
                <h2 className="text-4xl font-bold text-center text-violet-400 mb-8">Regulamin strony fazedev.pl</h2>
                <p className="text-center text-gray-400 text-sm mb-8">Data ostatniej aktualizacji: [02.11.2024]</p>
                
                <div className="space-y-8 text-gray-300 leading-relaxed">
                    <div>
                        <h3 className="text-xl font-semibold text-violet-300">1. Postanowienia ogólne</h3>
                        <p>1.1 Niniejszy Regulamin określa zasady korzystania ze strony internetowej fazedev.pl, dostępnej pod adresem <a href="https://www.fazedev.pl" className="text-violet-400 hover:underline">https://www.fazedev.pl</a>.</p>
                        <p>1.2 Strona prowadzona jest w ramach działalności niezarejestrowanej, zgodnie z przepisami polskiego prawa, a jej głównym celem jest informowanie o projektach zespołu oraz oferowanie funkcji związanych z Discordem i administracją.</p>
                        <p>1.3 Korzystając ze Strony, użytkownik akceptuje niniejszy Regulamin i zobowiązuje się do przestrzegania jego postanowień.</p>
                    </div>
                    
                    <div>
                        <h3 className="text-xl font-semibold text-violet-300">2. Definicje</h3>
                        <p>2.1 <span className="font-bold">Użytkownik</span> – każda osoba korzystająca ze Strony.</p>
                        <p>2.2 <span className="font-bold">Administrator</span> – właściciel strony fazedev.pl, który zarządza Stroną oraz danymi użytkowników.</p>
                        <p>2.3 <span className="font-bold">Działalność niezarejestrowana</span> – działalność prowadzona na mniejszą skalę, bez formalnego wpisu do rejestru przedsiębiorców.</p>
                    </div>

                    <div>
                        <h3 className="text-xl font-semibold text-violet-300">3. Zakres usług</h3>
                        <p>3.1 Strona oferuje funkcje, takie jak:
                            <ul className="list-disc list-inside ml-4">
                                <li>Informacje o projektach i zespole.</li>
                                <li>Panel administratora oraz integrację z Discordem dla wybranych użytkowników.</li>
                                <li>Sekcja kontaktowa umożliwiająca komunikację z Administratorem.</li>
                            </ul>
                        </p>
                        <p>3.2 Usługi dostępne na Stronie mają charakter informacyjny i są oferowane w ramach działalności niezarejestrowanej.</p>
                    </div>
                    
                    <div>
                        <h3 className="text-xl font-semibold text-violet-300">4. Zasady korzystania ze Strony</h3>
                        <p>4.1 Użytkownik zobowiązuje się do korzystania ze Strony w sposób zgodny z prawem, postanowieniami Regulaminu oraz dobrymi obyczajami.</p>
                        <p>4.2 Zabrania się podejmowania działań, które mogłyby zakłócić funkcjonowanie Strony, takich jak próby nieautoryzowanego dostępu do zasobów Strony czy ataki typu DDoS.</p>
                        <p>4.3 W przypadku wykrycia działań naruszających przepisy prawa lub postanowienia Regulaminu, Administrator ma prawo do podjęcia stosownych kroków, w tym blokady dostępu do Strony.</p>
                    </div>
                </div>
                
                <p className="mt-8 text-gray-400 text-sm text-center">Wszelkie pytania, uwagi oraz zgłoszenia dotyczące Strony i jej funkcjonowania należy kierować na adres e-mail: <a href="mailto:kontakt@fazedev.pl" className="text-violet-400 hover:underline">kontakt@fazedev.pl</a></p>
            </div>
       
    );
}

export default RulesSection;

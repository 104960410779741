import React, { useEffect, useState } from 'react';
import { HomeIcon, ClipboardDocumentCheckIcon, CheckCircleIcon, XCircleIcon, CreditCardIcon, ClockIcon, PencilIcon, UserIcon,ClipboardDocumentIcon, ExclamationCircleIcon, ClipboardDocumentListIcon,  ShoppingBagIcon,  UserGroupIcon } from '@heroicons/react/24/solid';
import {services,payments} from '../api/order';

import {fetchUserData,fetchOrdersData, roleButtonMapping,  fetchAllOrdersData, changePassword, changeEmail, checkUserRoles,  sendOrderRequest, availableRoles, fetchUsers,  assignRoleToUser } from '../api/backendAPI';

import routes from "../../utils/routes";
import useCheckCurrentPathAndScroll from "../../utils/useCheckCurrentPathAndScroll";
import logo from "../../img/logo.png";

function PanelUserSection() {
    /* eslint-disable react-hooks/exhaustive-deps */
    const checkCurrentPathAndScroll = useCheckCurrentPathAndScroll();
    const HOST = process.env.REACT_APP_BACKEND_HOST;
    const [isOrderListVisible, setIsOrderListVisible] = useState(false);
    const [isAllOrderListVisible, setIsAllOrderListVisible] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [allOrderList, setAllOrderList] = useState([]);
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]); 
    const [selectedUser, setSelectedUser] = useState(''); 
    const [selectedService, setSelectedService] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [customDescription, setCustomDescription] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedRole, setSelectedRole] = useState(null);
    const [activeTab, setActiveTab] = useState('main');
    const [avatarUrl, setAvatarUrl] = useState('');
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
 
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [oldEmail, setOldEmail] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isCooldown, setIsCooldown] = useState(false);
   
    const [currentPage, setCurrentPage] = useState(1);
    const ordersPerPage = 5; 
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = orderList.slice(indexOfFirstOrder, indexOfLastOrder);
    const totalPages = Math.ceil(orderList.length / ordersPerPage);


    const [currentAllOrdersPage, setAllCurrentPage] = useState(1); 
    const allOrdersPerPage = 3;  
    const indexOfLastAllOrder = currentAllOrdersPage * allOrdersPerPage;
    const indexOfFirstAllOrder = indexOfLastAllOrder - allOrdersPerPage;
    const currentAllOrders = allOrderList.slice(indexOfFirstAllOrder, indexOfLastAllOrder);
    const totalAllOrdersPages = Math.ceil(allOrderList.length / allOrdersPerPage);
    const [searchQuery, setSearchQuery] = useState('');



    const buttonsToDisplay = roles.reduce((acc, role) => {
        const normalizedRole = role.toLowerCase();
        const buttons = roleButtonMapping[normalizedRole] || [];
        return [...acc, ...buttons];
    }, []);

    const [orderStats, setOrderStats] = useState({
        'w trakcie realizacji': 0,
        'zrealizowane': 0,
        'zakończone': 0,
        'anulowane': 0,
        'przejęte': 0,
        'totalOrders': 0
        
    });


    const statusOrder = [
        'w trakcie realizacji',
        'zrealizowane',
        'zakończone',
        'anulowane',
      ];

    const handlePageChange = (page) => {
        setCurrentPage(page);
      };



    
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (query) {
            const foundOrder = allOrderList.find(order =>
                order.orderNumber.includes(query) ||
                order.nickname.toLowerCase().includes(query.toLowerCase()) ||
                order.description.toLowerCase().includes(query.toLowerCase())
            );
            if (foundOrder) {
                const orderIndex = allOrderList.indexOf(foundOrder);
                const newPage = Math.floor(orderIndex / allOrdersPerPage) + 1;
                setAllCurrentPage(newPage);
            }
        } else {
            setAllCurrentPage(1);
        }
    };
    
      
      
    const handleChangePassword = async () => {
        if (!oldPassword || !newPassword || !confirmPassword) {
            setErrorMessage('Wszystkie pola są wymagane.');
            return;
        }

        if (newPassword !== confirmPassword) {
            setErrorMessage('Nowe hasło i potwierdzenie hasła muszą być takie same.');
            return;
        }
        const success = await changePassword(oldPassword, newPassword);
        if (success) {
            alert('Hasło zostało pomyślnie zmienione!');
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setErrorMessage('');
        } else {
            setErrorMessage('Nie udało się zmienić hasła. Proszę spróbować ponownie.');
        }
    };


    const handleChangeEmail = async () => {
        if (!oldEmail|| !newEmail|| !confirmEmail) {
            setErrorMessage('Wszystkie pola są wymagane.');
            return;
        }

        if (newEmail!== confirmEmail) {
            setErrorMessage('Nowy Email i potwierdzenie emaila muszą być takie same.');
            return;
        }
        const success = await changeEmail(oldEmail, newEmail);
        if (success) {
            alert('Email został pomyślnie zmieniony!');
            setOldEmail('');
            setNewEmail('');
            setConfirmEmail('');
            setErrorMessage('');
        } else {
            setErrorMessage('Nie udało się zmienić email. Proszę spróbować ponownie.');
    
        }
    };

    const updateOrderStatus = async (nickname, orderNumber, newStatus) => {
        console.log('Updating status:', { nickname, orderNumber, newStatus });
        try {
            const response = await fetch(`${HOST}/api/order/${nickname}/${orderNumber}/${newStatus}`, {
                method: 'PUT',
                mode: 'cors', 
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus }),
            });
    
            if (response.ok) {
                alert('Status zamówienia został zaktualizowany pomyślnie.');
         
            } else {
                const errorData = await response.json();
                const errorMessage = errorData.message || 'Wystąpił problem z aktualizacją statusu zamówienia.';
                alert(errorMessage);
            }
        } catch (error) {
            console.error('Błąd przy aktualizacji statusu:', error);
            alert('Wystąpił błąd podczas aktualizacji statusu. Sprawdź połączenie z internetem lub spróbuj ponownie później.');
        }
    };

    const handleSelectOrder = (order) => {
        setSelectedOrder(order);
        setNewStatus(order.status); 


    };


    const fetchOrderStats = async () => {
        try {
            const response = await fetch(`${HOST}/api/orders/status`, {
                method: 'GET', 
                mode: 'cors', 
                headers: {
                    'Content-Type': 'application/json',
        
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
            setOrderStats(data);
        } catch (error) {
            console.error('Error fetching order stats:', error);
        }
    };

    const handleUpdateStatus = () => {
        if (selectedOrder) {
            console.log("Updating order status...");
    
            updateOrderStatus(selectedOrder.nickname, selectedOrder.orderNumber, newStatus)
                .then(() => {
                    console.log("Status updated. Calling toggleAllOrderList...");
                 
                })
                .then(() => {
                    console.log("Toggle completed. Updating selected order state.");
                    setSelectedOrder(null);
                    setNewStatus('');
                    refreshOrdersList();
                })
                .catch(error => {
                    console.error("Error during update: ", error);
                });
        }
    };
    
     const refreshOrdersList = () => {
        fetchAllOrdersData()
            .then(dataOrders => {
                setAllOrderList(dataOrders.orders || dataOrders); 
            })
            .catch(error => {
                console.error('Wystąpił błąd podczas odświeżania zamówień:', error);
            });
    };

    const handleClaimOrder = async (orderNumber) => {
        const username = localStorage.getItem('username');
        if (!username) {
            return alert('Musisz być zalogowany, aby przejąć zamówienie.');
        }
        const order = allOrderList.find(o => o.orderNumber === orderNumber);
        if (!order) {
            return alert('Nie znaleziono zamówienia.');
        }
        if (order.claimed && order.claimedBy !== username) {
            return alert('To zamówienie zostało już przejęte przez innego użytkownika.');
        }
        try {
            const response = await fetch(`${HOST}/api/claim-order/${username}/${orderNumber}`, {
                method: 'PUT', 
                mode: 'cors', 
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ claimed: true }), 
            });
    
            if (!response.ok) {
                const data = await response.json();
                return alert(data.message);
            }
    

            setAllOrderList(prevOrders =>
                prevOrders.map(o => 
                    o.orderNumber === orderNumber ? { ...o, claimed: true, claimedBy: username } : o
                )
            );
    
            alert(`Przejęto zamówienie przez: ${username}`);
        } catch (error) {
            console.error('Błąd podczas przejmowania zamówienia:', error); 
            alert('Wystąpił błąd podczas przejmowania zamówienia. Spróbuj ponownie.');
        }
    };
    
    
    
    

    useEffect(() => {
        const checkLoginStatus = async () => {
            const username = localStorage.getItem('username');
          
            if (!username) {
                checkCurrentPathAndScroll('panel', routes.panel);

            } else {
                await verifyUserRoles(username);

            }
            await fetchAvatar(username);
        };

        const verifyUserRoles = async (username) => {
            try {
                const {roles} = await checkUserRoles(username);
                setRoles(roles);
            } catch (error) {
                console.error('Nie udało się sprawdzić ról:', error);
            }
        };


        const fetchAvatar = async () => {
            const username = localStorage.getItem('username');
            if (username) {
                try {
                    const response = await fetch(`${HOST}/api/get-avatar/${username}`, {
                        method: 'GET',
                        mode: 'cors', 
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Błąd HTTP: ${response.status}`);
                    }

                    const data = await response.json();
                    setAvatarUrl(data.avatarUrl);
                } catch (error) {
                    console.error('Błąd przy pobieraniu awatara użytkownika:', error);
                }
            } else {
                console.warn('Brak nazwy użytkownika w localStorage.');
            }
        };

        checkLoginStatus().catch(console.error);
      

    }, []);







    const handleLogout = async () => {

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Brak tokenu');
                return;
            }

            const response = await fetch(`${HOST}/api/logout`, {
                method: 'POST',
                mode: 'cors', 
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    username: localStorage.getItem('username')
                })
            });

            if (response.ok) {
                localStorage.removeItem('token');
                localStorage.removeItem('username');
                checkCurrentPathAndScroll('panel', routes.panel);
            } else {
                const errorData = await response.json();
                console.error('Błąd podczas wylogowywania:', errorData.error);
            }
        } catch (error) {
            console.error('Wystąpił błąd:', error);
        }
    };


    const toggleAllOrderList = async () => {
        if (!isAllOrderListVisible) {
            try {
                const dataOrders = await fetchAllOrdersData();
                setAllOrderList(dataOrders.orders || dataOrders);
            } catch (error) {
                console.error('Wystąpił błąd:', error);
                setErrorMessage('Nie udało się pobrać zamówień. Spróbuj ponownie później.');
            }
        }

        setIsAllOrderListVisible(!isAllOrderListVisible);
    };



    const toggleOrderList = async () => {
        if (!isOrderListVisible) {
            try {
                const username = localStorage.getItem('username');
                if (!username) {
                    return;
                }
                await fetchUserData(username);
           
                const dataOrders = await fetchOrdersData(username);
                setOrderList(dataOrders.orders || dataOrders);
            
            } catch (error) {
                setErrorMessage('Nie udało się pobrać zamówień. Spróbuj ponownie później.');
            }
        }

        setIsOrderListVisible(!isOrderListVisible);
    };


  



    const handleRoleManagementClick = async () => {
        try {
            const data = await fetchUsers();
            setUsers(data);
        } catch (error) {
            console.error('Wystąpił błąd podczas pobierania użytkowników:', error);
        }
    };

    const handleOrder = async () => {

        if (!selectedPayment) {
            setErrorMessage('Wybierz metode płatności.');
            return;
        }
        if (!selectedService) {
            setErrorMessage('Wybierz usługę.');
            return;
        }
      
        if (!customDescription) {
            setErrorMessage('Wpisz swój opis zamówienia.');
            return;
        }
    
        try {
            const orderData = {
                id: selectedService.id,
                PaymentName: selectedPayment.name, 
                description: customDescription,
                nickname: localStorage.getItem('username'), 
            };
    
            await sendOrderRequest(orderData);

            setIsCooldown(true);
            setShowAlert(true);

    

            setTimeout(() => {
                setShowAlert(false);
            }, 3000);

        

            setTimeout(() => {
                setIsCooldown(false);
            }, 300000); 



            setSelectedService(null);
            setSelectedPayment(null);
            setCustomDescription('');
 
    
        } catch (error) {
            console.error('Błąd:', error);
            if (error instanceof TypeError) {
                setErrorMessage('Błąd sieci: sprawdź połączenie internetowe.');
            } else {
                setErrorMessage('Nie udało się złożyć zamówienia. Spróbuj ponownie. Szczegóły błędu: ' + error.message);
            }
        }
    };
    


    const handleRoleChange = async () => {
        if (!selectedRole) {
            setErrorMessage('Proszę wybrać rolę do przypisania.');
            return;
        }

        try {
            await assignRoleToUser(selectedUser, selectedRole);
            alert(`Rola ${selectedRole} została pomyślnie przypisana!`);

            setSelectedRole('');
        } catch (error) {
            console.error('Błąd przypisywania roli:', error);
            setErrorMessage('Nie udało się przypisać roli. Proszę spróbować ponownie.');
        }
    };

    const toggleMobileSidebar = () => {
        setIsMobileSidebarOpen(!isMobileSidebarOpen);
    };

    return (
        <div className="min-h-screen flex flex-col lg:flex-row">
    
            {/* Mobile Sidebar Button */}
            <button
  onClick={toggleMobileSidebar}
  className="lg:hidden p-4 text-white rounded-md fixed top-2 left-2 z-50  transition-all duration-300 ease-in-out shadow-lg focus:outline-none"
>
  {/* Ikonka menu na urządzeniach mobilnych */}
  {isMobileSidebarOpen ? (
    <span className="text-2xl">&#10005;</span>  
  ) : (
    <span className="text-2xl">&#9776;</span>  
  )}
</button>


           
      
      
        {/* desktop */}
        <aside className="hidden lg:block bg-gradient-to-r from-sidebarFrom to-sidebarTo w-64 text-gray-100 fixed top-0 bottom-0 lg:h-screen rounded-xl shadow-xl overflow-y-auto">
            <div className="p-6">
                <div className="relative flex items-center justify-center">

                <img  src={logo} alt="Logo FazeDev" onClick={() => checkCurrentPathAndScroll('slider', routes.home)} className="w-16 cursor-pointer lg:w-10 absolute left-0"/>

                    <h2 className="text-xl font-bold text-[#6a40ff]">Menu </h2>
                </div>

                <div className="relative flex flex-col items-center justify-center mt-6">
                    <img src={avatarUrl} alt="Avatar"
                        className="w-16 h-16 rounded-full cursor-pointer border-2 border-[#6a40ff] transition-all duration-300 hover:scale-110"/>
                    <h2 className="mt-2 text-lg font-semibold">{localStorage.getItem('username')}</h2>
                </div>

                <button
                    onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                    className={`block w-full text-left px-4 py-3 mt-4 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none`}
                >
                    <div className="flex items-center">
                        <UserIcon className="h-5 w-5 mr-2"/>
                        Profil
                    </div>
                </button>

                {isProfileMenuOpen && (
                    <ul className="ml-4 mt-2">
                        {/* <li>
                            <button
                                onClick={() => setActiveTab('changePassword')}
                                className={`block w-full text-left px-4 py-2 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none`}
                            >
                                Zmień Hasło
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() => setActiveTab('changeEmail')}
                                className={`block w-full text-left px-4 py-2 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none`}
                            >
                                Zmień Email
                            </button>
                        </li> */}
                        <li>
                            <button
                                onClick={() => handleLogout()}
                                className={`block w-full text-left px-4 py-2 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none`}
                            >
                                Wyloguj się
                            </button>
                        </li>
                    </ul>
                )}

                <button
                    onClick={() => checkCurrentPathAndScroll('reviews', routes.reviews)}
                    className={`block w-full text-left px-4 py-3 mt-4 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none`}
                >
                    <div className="flex items-center">
                        <ClipboardDocumentIcon className="h-5 w-5 mr-2"/>
                        Opinie
                    </div>
                </button>

                <button
                    onClick={() => setActiveTab('main')}
                    className={`block w-full text-left px-4 py-3 mt-2 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none`}
                >
                    <div className="flex items-center">
                        <HomeIcon className="h-5 w-5 mr-2"/>
                        Panel Główny
                    </div>
                </button>

                <div className="my-4 flex items-center">
                    <span className="flex-grow h-px bg-gray-600"/>
                    <span className="mx-2 text-gray-400">Zamówienia</span>
                    <span className="flex-grow h-px bg-gray-600"/>
                </div>

                <ul className="mt-2">
                    <li className="mt-2">
                        <button
                            onClick={() => {
                                setActiveTab('orders');
                                toggleOrderList();
                            }}
                            className={`block w-full text-left px-4 py-3 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none`}
                        >
                            <div className="flex items-center">
                                <ClipboardDocumentCheckIcon className="h-5 w-5 mr-2"/>
                                Pokaż Zamówienia
                            </div>
                        </button>
                    </li>

                    <li className="mt-2">
                        <button
                            onClick={() => {
                                setActiveTab('orderService');
                                toggleOrderList();
                            }}
                            className={`block w-full text-left px-4 py-3 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none`}
                        >
                            <div className="flex items-center">
                                <ShoppingBagIcon className="h-5 w-5 mr-2"/>
                                Złoż zamówienie
                            </div>
                        </button>
                    </li>
                </ul>

                {['adminButton', 'supportButton'].some(button => buttonsToDisplay.includes(button)) && (
                    <div className="my-4 flex items-center">
                        <span className="flex-grow h-px bg-gray-600"/>
                        <span className="mx-2 text-gray-400">Zarządzanie</span>
                        <span className="flex-grow h-px bg-gray-600"/>
                    </div>
                )}

                {['adminButton', 'supportButton'].some(button => buttonsToDisplay.includes(button)) && (
                    <ul className="mt-2">
                        <li className="mt-2">
                            <button
                                onClick={() => {
                                    setActiveTab('allOrders');
                                    toggleAllOrderList();
                                }}
                                className={`block w-full text-left px-4 py-3 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none`}
                            >
                                <div className="flex items-center">
                                    <ClipboardDocumentListIcon className="h-5 w-5 mr-2"/>
                                    Pokaż Wszystkie Zamówienia
                                </div>
                            </button>
                        </li>

                        <li className="mt-2">
                            <button
                                onClick={() => {
                                    setActiveTab('OrderStatistics');
                                    fetchOrderStats();
                                }}
                                className={`block w-full text-left px-4 py-3 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none`}
                            >
                                <div className="flex items-center">
                                    <UserGroupIcon className="h-5 w-5 mr-2"/>
                                    statystyki Zamówień
                                </div>
                            </button>
                            <button
                             onClick={() => {
                               setActiveTab('chat');
                                }}

                                className={`block w-full text-left px-4 py-3 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none`}
                            >
                                <div className="flex items-center">
                                    <UserGroupIcon className="h-5 w-5 mr-2"/>
                                    Chat Administratorów
                                </div>
                            </button>
                        </li>
                    </ul>
                )}

                {['WlascielButton'].some(button => buttonsToDisplay.includes(button)) && (
                    <ul className="mt-2">
                        <li className="mt-2">
                            <button
                                onClick={() => {
                                    setActiveTab('roleManagement');
                                    handleRoleManagementClick();
                                }}
                                className={`block w-full text-left px-4 py-3 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none`}
                            >
                                <div className="flex items-center">
                                    <ClipboardDocumentListIcon className="h-5 w-5 mr-2"/>
                                    Zarządzaj Rolami
                                </div>
                            </button>
                        </li>
                    </ul>
                )}

            </div>
        </aside>
        {isMobileSidebarOpen && (
            <aside
    className="lg:hidden fixed top-0 left-0 w-full bg-gradient-to-r from-sidebarFrom to-sidebarTo text-gray-100 shadow-lg rounded-b-xl z-40"
    data-aos="fade-in"           // Dodajemy animację AOS
    data-aos-duration="300"      // Czas trwania animacji (500ms)
    data-aos-delay="200"         // Opóźnienie animacji (opcjonalnie, 200ms)
  >

    <div className="flex flex-col items-center p-4 space-y-4">

      <img  src={logo} alt="Logo FazeDev" onClick={() => checkCurrentPathAndScroll('slider', routes.home)} className="w-12 h-12 cursor-pointer"/>

      <div className="text-lg font-bold text-[#6a40ff]">Menu</div>

      <button
        onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
        className="flex items-center space-x-2 w-full text-left px-4 py-2 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd]"
      >
        <UserIcon className="h-5 w-5" />
        Profil
      </button>

      {isProfileMenuOpen && (
        <div className="space-y-2 px-4">
          {/* <button
            onClick={() => setActiveTab('changePassword')}
            className="block w-full text-left px-4 py-2 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd]"
          >
            Zmień Hasło
          </button>
          <button
            onClick={() => setActiveTab('changeEmail')}
            className="block w-full text-left px-4 py-2 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd]"
          >
            Zmień Email
          </button> */}
          <button
            onClick={() => handleLogout()}
            className="block w-full text-left px-4 py-2 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd]"
          >
            Wyloguj się
          </button>
        </div>
      )}

      <button
        onClick={() => checkCurrentPathAndScroll('reviews', routes.reviews)}
        className="block w-full text-left px-4 py-2 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd]"
      >
        <div className="flex items-center">
          <ClipboardDocumentIcon className="h-5 w-5 mr-2" />
          Opinie
        </div>
      </button>

      <button
        onClick={() => setActiveTab('main')}
        className="block w-full text-left px-4 py-2 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd]"
      >
        <div className="flex items-center">
          <HomeIcon className="h-5 w-5 mr-2" />
          Panel Główny
        </div>
      </button>

      <button
        onClick={() => {
          setActiveTab('orders');
          toggleOrderList();
        }}
        className="block w-full text-left px-4 py-2 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd]"
      >
        <div className="flex items-center">
          <ClipboardDocumentCheckIcon className="h-5 w-5 mr-2" />
          Pokaż Zamówienia
        </div>
      </button>

      <button
        onClick={() => {
          setActiveTab('orderService');
          toggleOrderList();
        }}
        className="block w-full text-left px-4 py-2 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd]"
      >
        <div className="flex items-center">
          <ShoppingBagIcon className="h-5 w-5 mr-2" />
          Złóż zamówienie
        </div>
      </button>

      {['adminButton', 'supportButton'].some(button => buttonsToDisplay.includes(button)) && (
        <>
          <button
            onClick={() => {
              setActiveTab('allOrders');
              toggleAllOrderList();
            }}
            className="block w-full text-left px-4 py-3 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none"
          >
            <div className="flex items-center">
              <ClipboardDocumentListIcon className="h-5 w-5 mr-2" />
              Pokaż Wszystkie Zamówienia
            </div>
          </button>

          <button
            onClick={() => {
              setActiveTab('OrderStatistics');
              fetchOrderStats();
            }}
            className="block w-full text-left px-4 py-3 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none"
          >
            <div className="flex items-center">
              <UserGroupIcon className="h-5 w-5 mr-2" />
              Statystyki Zamówień
            </div>
          </button>

          <button
            onClick={() => {
              setActiveTab('chat');
            
            }}
            className="block w-full text-left px-4 py-3 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none"
          >
            <div className="flex items-center">
              <UserGroupIcon className="h-5 w-5 mr-2" />
              Chat Administratorów
            </div>
          </button>
        </>
      )}

      {['WlascielButton'].some(button => buttonsToDisplay.includes(button)) && (
        <>
          <button
            onClick={() => {
              setActiveTab('roleManagement');
              handleRoleManagementClick();
            }}
            className="block w-full text-left px-4 py-3 text-sm font-semibold rounded-lg transition-colors duration-300 hover:bg-[#4a26bd] focus:outline-none"
          >
            <div className="flex items-center">
              <ClipboardDocumentListIcon className="h-5 w-5 mr-2" />
              Zarządzaj Rolami
            </div>
          </button>
        </>
      )}
    </div>
  </aside>
)}


<div className="flex-1 flex flex-col pt-20">

  
                {activeTab === 'main' && (
                    <>
                   
         
                        <div className="text-center">
                            <p className="text-4xl font-extrabold text-white mb-4 mt-5">Witaj w Panelu
                                Użytkownika!</p>

                            <p className="text-gray-200 mb-6 mt-4">
                                Tutaj możesz zamówić dostępne usługi. Przeglądaj i wybieraj to, co najlepsze dla
                                Ciebie!
                            </p>



                        
                            <p className="text-4xl font-extrabold text-white mb-2 mt-20">Dostępne Usługi:</p>
                        </div>

                        <div
                            className="mt-20  max-w-sm mx-auto grid gap-6 lg:grid-cols-3 items-start lg:max-w-none group"
                            data-spotlight="">

                            <div
                                className="relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden ">
                                <div
                                    className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden ">
                                    <div
                                        className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                                        aria-hidden="true">
                                        <div
                                            className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                                    </div>
                                    <div className="flex flex-col h-full items-center text-center">
                                        <div className="relative inline-flex">
                                            <div
                                                className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                                                aria-hidden="true"></div>
                                            <i className="fab fa-discord text-transparent bg-gradient-to-r from-[#2594e9] to-[#1c7bb4] bg-clip-text mr-2 text-5xl animate-pulse"></i>

                                        </div>
                                        <div className="grow mb-5">
                                            <h2 className="text-xl text-slate-200 font-bold mb-1">Serwer
                                                Discord</h2>
                                            <p className="text-sm text-slate-500">Wykonamy dla Ciebie serwer
                                                Discord,</p>
                                            <p className="text-sm text-slate-500">dopasowany do Twoich wymagań.</p>
                                        </div>
                                        <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                                           href="#0">
                                            <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                 width="16" height="14">
                                                <path
                                                    d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                                            </svg>
                                            <span>cena: indiwidualna</span>
                                        </a>
                                    </div>
                                </div>
                            </div>


                            <div
                                className="relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
                                <div
                                    className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
                                    <div
                                        className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                                        aria-hidden="true">
                                        <div
                                            className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                                    </div>
                                    <div className="flex flex-col h-full items-center text-center">
                                        <div className="relative inline-flex">
                                            <div
                                                className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                                                aria-hidden="true"></div>
                                            <i className="fas fa-robot text-transparent bg-gradient-to-r from-[#7289da] via-[#5f7bec] to-[#3e5bc3] bg-clip-text mr-2 text-5xl animate-pulse"></i>
                                        </div>
                                        <div className="grow mb-5">
                                            <h2 className="text-xl text-slate-200 font-bold mb-1">Bot Discord</h2>
                                            <p className="text-sm text-slate-500">Stworzymy dla Ciebie dowolnego
                                                bota
                                                Discord,</p>
                                            <p className="text-sm text-slate-500">który będzie dostosowany</p>
                                            <p className="text-sm text-slate-500">do Twoich potrzeb
                                                i wymagań.</p>

                                        </div>

                                        <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                                           href="#0">
                                            <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                 width="16" height="14">
                                                <path
                                                    d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                                            </svg>
                                            <span>cena: indiwidualna</span>
                                        </a>
                                    </div>
                                </div>
                            </div>


                            <div
                                className="relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
                                <div
                                    className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
                                    <div
                                        className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                                        aria-hidden="true">
                                        <div
                                            className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                                    </div>
                                    <div className="flex flex-col h-full items-center text-center">
                                        <div className="relative inline-flex">
                                            <div
                                                className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                                                aria-hidden="true"></div>
                                            <i className="fas fa-globe text-transparent bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-700 bg-clip-text mr-2 text-5xl animate-pulse"></i>

                                        </div>
                                        <div className="grow mb-5">
                                            <h2 className="text-xl text-slate-200 font-bold mb-1">Strona
                                                Internetowa</h2>
                                            <p className="text-sm text-slate-500">Stworzymy dla Ciebie unikalną
                                                stronę
                                                internetową,</p>
                                            <p className="text-sm text-slate-500">która będzie funkcjonalna,
                                                estetyczna
                                                i
                                                responsywna.</p>
                                            <p className="text-sm text-slate-500">Z nami zbudujesz silną obecność w
                                                sieci!</p>
                                        </div>


                                        <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                                           href="#0">
                                            <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                 width="16" height="14">
                                                <path
                                                    d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                                            </svg>
                                            <span>cena: indiwidualna</span>
                                        </a>
                                    </div>
                                </div>
                            </div>


                            <div
                                className="relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
                                <div
                                    className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
                                    <div
                                        className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                                        aria-hidden="true">
                                        <div
                                            className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                                    </div>
                                    <div className="flex flex-col h-full items-center text-center">
                                        <div className="relative inline-flex">
                                            <div
                                                className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                                                aria-hidden="true"></div>
                                            <i className="fas fa-image text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text mr-2 text-5xl animate-pulse"></i>


                                        </div>
                                        <div className="grow mb-5">
                                            <h2 className="text-xl text-slate-200 font-bold mb-1">Grafiki</h2>
                                            <p className="text-sm text-slate-500">Stworzymy dla Ciebie unikalne
                                                grafiki,</p>
                                            <p className="text-sm text-slate-500">które wyróżnią Twoją markę i
                                                przyciągną
                                                uwagę.</p>
                                        </div>

                                        <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                                           href="#0">
                                            <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                 width="16" height="14">
                                                <path
                                                    d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                                            </svg>
                                            <span>cena: indiwidualna</span>
                                        </a>
                                    </div>
                                </div>
                            </div>


                            <div
                                className="relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
                                <div
                                    className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
                                    <div
                                        className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                                        aria-hidden="true">
                                        <div
                                            className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                                    </div>
                                    <div className="flex flex-col h-full items-center text-center">
                                        <div className="relative inline-flex">
                                            <div
                                                className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                                                aria-hidden="true"></div>
                                            <i className="fas fa-film text-transparent bg-gradient-to-r from-red-400 via-yellow-400 to-purple-600 bg-clip-text mr-2 text-5xl animate-pulse "></i>

                                        </div>
                                        <div className="grow mb-5">
                                            <h2 className="text-xl text-slate-200 font-bold mb-1">Montaż Filmów</h2>
                                            <p className="text-sm text-slate-500">Zmontujemy film dla Ciebie,</p>
                                            <p className="text-sm text-slate-500">dopasowany do Twoich
                                                oczekiwań.</p>

                                        </div>
                                        <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                                           href="#0">
                                            <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                 width="16" height="14">
                                                <path
                                                    d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                                            </svg>
                                            <span>cena: indiwidualna</span>
                                        </a>
                                    </div>
                                </div>
                            </div>


                            <div
                                className="relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
                                <div
                                    className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
                                    <div
                                        className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                                        aria-hidden="true">
                                        <div
                                            className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                                    </div>
                                    <div className="flex flex-col h-full items-center text-center">
                                        <div className="relative inline-flex">
                                            <div
                                                className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                                                aria-hidden="true"></div>
                                            <i className="fas fa-cube text-transparent bg-gradient-to-r from-green-500 via-green-700 to-brown-500 bg-clip-text mr-2 text-5xl animate-pulse"></i>


                                        </div>
                                        <div className="grow mb-5">
                                            <h2 className="text-xl text-slate-200 font-bold mb-1">Trailery
                                                Minecraft </h2>
                                            <p className="text-sm text-slate-500">Stworzymy dla Ciebie unikalny
                                                trailer
                                                Minecraft,</p>
                                            <p className="text-sm text-slate-500">który przyciągnie uwagę Twojej
                                                społeczności.</p>

                                        </div>
                                        <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                                           href="#0">
                                            <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                 width="16" height="14">
                                                <path
                                                    d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                                            </svg>
                                            <span>cena: indiwidualna</span>
                                        </a>
                                    </div>
                                </div>
                            </div>


                            <div
                                className="relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
                                <div
                                    className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
                                    <div
                                        className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                                        aria-hidden="true">
                                        <div
                                            className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                                    </div>
                                    <div className="flex flex-col h-full items-center text-center">
                                        <div className="relative inline-flex">
                                            <div
                                                className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                                                aria-hidden="true"></div>
                                            <i className="fas fa-clapperboard text-transparent bg-gradient-to-r from-red-400 via-yellow-400 to-green-500 bg-clip-text mr-2 text-5xl animate-pulse"></i>


                                        </div>
                                        <div className="grow mb-5">
                                            <h2 className="text-xl text-slate-200 font-bold mb-1">Animacje</h2>
                                            <p className="text-sm text-slate-500">Stworzymy dla Ciebie efektowne
                                                animacje,</p>
                                            <p className="text-sm text-slate-500">które ożywią Twoje projekty w
                                                Minecraft.</p>

                                        </div>
                                        <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                                           href="#0">
                                            <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                 width="16" height="14">
                                                <path
                                                    d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                                            </svg>
                                            <span>cena: indiwidualna</span>
                                        </a>
                                    </div>
                                </div>
                            </div>


                            <div
                                className="relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
                                <div
                                    className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
                                    <div
                                        className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                                        aria-hidden="true">
                                        <div
                                            className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                                    </div>
                                    <div className="flex flex-col h-full items-center text-center">
                                        <div className="relative inline-flex">
                                            <div
                                                className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                                                aria-hidden="true"></div>
                                            <i className="fas fa-plug text-transparent bg-gradient-to-r from-orange-400 via-orange-600 to-orange-800 bg-clip-text mr-2 text-5xl animate-pulse"></i>


                                        </div>
                                        <div className="grow mb-5">
                                            <h2 className="text-xl text-slate-200 font-bold mb-1">Plugin
                                                Minecraft</h2>
                                            <p className="text-sm text-slate-500">Oferujemy stworzenie dostosowanego
                                                pluginu,</p>
                                            <p className="text-sm text-slate-500">który wzbogaci Twoją
                                                rozgrywkę.</p>

                                        </div>
                                        <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                                           href="#0">
                                            <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                 width="16" height="14">
                                                <path
                                                    d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                                            </svg>
                                            <span>cena: indiwidualna</span>
                                        </a>
                                    </div>
                                </div>
                            </div>


                            <div
                                className="relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
                                <div
                                    className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
                                    <div
                                        className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                                        aria-hidden="true">
                                        <div
                                            className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                                    </div>
                                    <div className="flex flex-col h-full items-center text-center">
                                        <div className="relative inline-flex">
                                            <div
                                                className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                                                aria-hidden="true"></div>
                                            <i className="fas fa-archive text-transparent bg-gradient-to-r from-red-400 via-red-600 to-red-800 bg-clip-text mr-2 text-5xl animate-pulse"></i>


                                        </div>
                                        <div className="grow mb-5">
                                            <h2 className="text-xl text-slate-200 font-bold mb-1">Paczki
                                                Minecraft</h2>
                                            <p className="text-sm text-slate-500">Zaprojektujemy paczki
                                                Minecraft,</p>
                                            <p className="text-sm text-slate-500">które dostosują Twoje
                                                doświadczenie w
                                                grze.</p>

                                        </div>
                                        <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                                           href="#0">
                                            <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                 width="16" height="14">
                                                <path
                                                    d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                                            </svg>
                                            <span>cena: indiwidualna</span>
                                        </a>
                                    </div>
                                </div>
                            </div>


                            <div
                                className="relative h-full bg-custom-gradient rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
                                <div
                                    className="relative h-full bg-custom-gradient p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
                                    <div
                                        className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square"
                                        aria-hidden="true">
                                        <div
                                            className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                                    </div>
                                    <div className="flex flex-col h-full items-center text-center">
                                        <div className="relative inline-flex">
                                            <div
                                                className="w-[40%] h-[40%] absolute inset-0 m-auto -translate-y-[10%] blur-3xl -z-10 rounded-full bg-indigo-600"
                                                aria-hidden="true"></div>
                                            <i className="fas fa-axe text-transparent bg-gradient-to-r from-[#A0522D] via-[#CD853F] to-[#D2B48C] bg-clip-text mr-2 text-5xl animate-pulse"></i>


                                        </div>
                                        <div className="grow mb-5">
                                            <h2 className="text-xl text-slate-200 font-bold mb-1">Budowle
                                                Minecraft</h2>
                                            <p className="text-sm text-slate-500">Zrealizujemy dla Ciebie unikalne
                                                budowle,</p>
                                            <p className="text-sm text-slate-500">które wyróżnią Twój świat w
                                                Minecraft.</p>

                                        </div>
                                        <a className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150"
                                           href="#0">
                                            <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg"
                                                 width="16" height="14">
                                                <path
                                                    d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z"/>
                                            </svg>
                                            <span>cena: indiwidualna</span>
                                        </a>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </>
                )}
        
                {activeTab === 'orders' && (
  <div className="min-h-screen bg-custom-panel p-6">
    <div className="max-w-6xl mx-auto">
    <h1 className="text-center text-3xl font-bold text-white mb-8">
  Twoje Zamówienia
</h1>




<div className="space-y-4 overflow-x-auto whitespace-nowrap ">

        {statusOrder.map((status) => {
          const filteredOrders = currentOrders.filter((order) => order.status === status);

          if (filteredOrders.length === 0) return null;  

          return (
            <div key={status} className="space-y-4">
              {filteredOrders.map((order) => (
                <div
                  key={order.orderNumber}
                  className="bg-gradient-to-r from-[#18181a] to-[#101010] rounded-lg shadow-md hover:shadow-lg transition-all duration-300 p-6"
                  role="listitem"
                  aria-label={`Order ${order.orderNumber}`}
                >
                  <div className="flex justify-between items-center">
                    {/* Left side */}
                    <div className="flex items-center gap-4">
                      <span className="text-lg font-semibold text-gray-400">
                        {order.orderNumber}
                      </span>
                      <span
                        className={`px-3 py-1 rounded-full text-sm font-medium ${
                          order.status === 'zrealizowane'
                            ? 'bg-green-500 text-white'  
                            : order.status === 'w trakcie realizacji'
                            ? 'bg-blue-500 text-white'  
                            : order.status === 'zakończone'
                            ? 'bg-orange-500 text-white'  
                            : 'bg-red-500 text-white'  
                        }`}
                      >
                        {order.status}
                      </span>
                    </div>
                  </div>

                  {/* Below items */}
                  <div className="mt-4 flex justify-between">
                    <div>
                      <p className="text-white font-medium">Użytkownik: {order.nickname}</p>
                      <p className="mt-2 text-sm text-gray-400">Opis zamówienia: {order.description}</p>
                    </div>

                    <div className="flex space-x-4 gap-2 text-right">
  <p className="text-sm text-gray-400 flex items-center gap-1">

    <ClipboardDocumentListIcon  className="h-4 w-4 text-gray-400" />
    Usługa: {order.serviceName}
  </p>
  
  <p className="text-sm text-gray-400 flex items-center gap-1">

    <ClockIcon className="h-4 w-4 text-gray-400" />
    Data Zamówienia: {new Date(order.createdAt).toLocaleDateString()}
  </p>

  <p className="text-sm text-gray-400 flex items-center gap-1">
    
    <CreditCardIcon className="h-4 w-4 text-gray-400" />
    Metoda płatności: {order.PaymentName}
  </p>
</div>
                  </div>
                </div>
              ))}
            </div>
          );
        })}

        {/* Paginacja */}
        <div className="flex justify-center mt-6">
          <button
            className="px-4 py-2 bg-gray-700 text-white rounded-md mx-2"
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {'<'}
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`px-4 py-2 mx-1 rounded-md ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-700 text-white'}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="px-4 py-2 bg-gray-700 text-white rounded-md mx-2"
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            {'>'}
          </button>
        </div>
      </div>
    </div>
  </div>
)}





{activeTab === 'allOrders' && (
  <div className="min-h-screen bg-custom-panel p-6">
    <div className="max-w-6xl mx-auto">
      <h1 className="text-center text-3xl font-bold text-white mb-8">
        Wszystkie Zamówienia
      </h1>

      {/* Pole wyszukiwania */}
      <div className="max-w-6xl mx-auto mb-6">
      <input
  type="text"
  placeholder="Szukaj zamówienia..."
  value={searchQuery}
  onChange={handleSearchChange} 
  className="w-full p-2 border border-gray-600 rounded bg-gradient-to-r from-[#242424] to-[#18181a] text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
/>
      </div>

      <div className="space-y-4 overflow-x-auto whitespace-nowrap ">

        {statusOrder.map((status) => {
          const filteredOrders = currentAllOrders
            .filter((order) => order.status === status)
            .filter((order) => 
              order.orderNumber.includes(searchQuery) ||
              order.nickname.toLowerCase().includes(searchQuery.toLowerCase()) ||
              order.description.toLowerCase().includes(searchQuery.toLowerCase())
            );

          if (filteredOrders.length === 0) return null;

          return (
            <div key={status} className="space-y-4">
              {filteredOrders.map((order) => (
                <div
                  key={order.orderNumber}
                  className="bg-gradient-to-r from-[#18181a] to-[#101010] rounded-lg shadow-md hover:shadow-lg transition-all duration-300 p-6"
                  role="listitem"
                  aria-label={`Order ${order.orderNumber}`}
                >
                  <div className="flex justify-between items-center">
                    {/* Left side */}
                    <div className="flex items-center gap-4">
                      <span className="text-lg font-semibold text-gray-400">
                        {order.orderNumber}
                      </span>
                      <span
                       className={`px-3 py-1 rounded-full text-sm font-medium ${
                          order.status === 'zrealizowane'
                            ? 'bg-green-500 text-white'  
                            : order.status === 'w trakcie realizacji'
                            ? 'bg-blue-500 text-white'  
                            : order.status === 'zakończone'
                            ? 'bg-orange-500 text-white'  
                            : 'bg-red-500 text-white'  
                        }`}
                      >
                        {order.status}
                      </span>
                    </div>
                  </div>

                  <div className="mt-4 flex justify-between">
                    <div>
                      <p className="text-white font-medium">Użytkownik: {order.nickname}</p>
                      <p className="mt-2 text-sm text-gray-400">Opis zamówienia: {order.description}</p>

                      <div className="flex gap-2 mt-4">
                        <button
                          onClick={() => handleSelectOrder(order)}
                          className="bg-blue-600 text-white py-1 px-3 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                          Wybierz
                        </button>

                        {!order.claimed ? (
                          <button
                            onClick={() => handleClaimOrder(order.orderNumber)}
                            className="bg-green-600 text-white py-1 px-3 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                          >
                            Przejmij zamówienie
                          </button>
                        ) : (
                          <button
                            className="bg-red-600 text-white py-1 px-3 rounded-md cursor-not-allowed"
                            disabled
                          >
                      
                              Przejęte przez {order.claimedBy}
                           
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="flex space-x-4 gap-2 text-right">
                      <p className="text-sm text-gray-400 flex items-center gap-1">
                        <ClipboardDocumentListIcon className="h-4 w-4 text-gray-400" />
                        Usługa: {order.serviceName}
                      </p>
                      <p className="text-sm text-gray-400 flex items-center gap-1">
                        <ClockIcon className="h-4 w-4 text-gray-400" />
                        Data Zamówienia: {new Date(order.createdAt).toLocaleDateString()}
                      </p>
                      <p className="text-sm text-gray-400 flex items-center gap-1">
                        <CreditCardIcon className="h-4 w-4 text-gray-400" />
                        Metoda płatności: {order.PaymentName}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          );
        })}

        {selectedOrder && (
          <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-80 bg-[#18181a] p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-semibold text-fuchsia-300 mb-4">Zmień status zamówienia</h2>
            <label className="block mb-2 text-gray-300">Numer zamówienia: {selectedOrder.orderNumber}</label>
            <label className="block mb-2 text-gray-300">Wybierz status:</label>
            <select
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              className="p-2 border border-gray-600 rounded bg-gradient-to-r from-[#242424] to-[#18181a] text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="" disabled>Wybierz status</option>
              <option value="w trakcie realizacji">W trakcie realizacji</option>
              <option value="zrealizowane">Zrealizowane</option>
              <option value="zakończone">Zakończone</option>
              <option value="anulowane">Anulowane</option>
            </select>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setSelectedOrder(null)}
                className="mr-2 text-gray-400 hover:text-gray-300"
              >
                Anuluj
              </button>
              <button
                onClick={() => handleUpdateStatus(selectedOrder.nickname, selectedOrder.orderNumber, selectedOrder.orderId)}
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
              >
                Zatwierdź
              </button>
            </div>
          </div>
        )}

        {/* Paginacja */}
        <div className="flex justify-center mt-6">
          <button
            className="px-4 py-2 bg-gray-700 text-white rounded-md mx-2"
            onClick={() => setAllCurrentPage(currentAllOrdersPage - 1)}
            disabled={currentAllOrdersPage === 1}
          >
            {'<'}
          </button>
          {[...Array(totalAllOrdersPages)].map((_, index) => (
            <button
              key={index}
              className={`px-4 py-2 mx-1 rounded-md ${currentAllOrdersPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-700 text-white'}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="px-4 py-2 bg-gray-700 text-white rounded-md mx-2"
            onClick={() => setAllCurrentPage(currentAllOrdersPage + 1)}
            disabled={currentAllOrdersPage === totalAllOrdersPages}
          >
            {'>'}
          </button>
        </div>
      </div>
    </div>
  </div>
)}





      
      
{/* 

                {activeTab === 'allOrders1' && (
    <div className="flex flex-col items-center justify-center mx-auto mt-5 mb-5 border border-gray-600 bg-gradient-to-r  from-[#18181a] to-[#101010] p-8 rounded-lg shadow-lg transition-transform transform hover:scale-105 w-full max-w-6xl">
        <h4 className="text-lg text-fuchsia-300 mb-6 text-center">Lista Wszystkich Zamówień:</h4>
        {allOrderList.length === 0 ? (
            <p className="text-center text-gray-300">Brak zamówień do wyświetlenia.</p>
        ) : (
            <div className="w-full max-h-[400px] overflow-y-auto px-4">
                <ul className="text-gray-200 w-full">
                    {allOrderList.map(order => (
                        <li key={order.orderNumber} className="hover:bg-order-hover p-3 rounded transition duration-200 flex justify-between items-center">
                            <div>
                            <div className="order-details">
                            <strong>Numer Zamówienia:</strong> <span>{order.orderNumber}</span><br />
                            <strong>Usługa:</strong> <span>{order.serviceName}</span><br />
                            <strong>ID:</strong> <span>{order.serviceId}</span><br />
                            <strong>Opis:</strong> <span>{order.description}</span><br />
                            <strong>Nick:</strong> <span>{order.nickname}</span><br />
                            <strong>Metoda Płatności:</strong> <span>{order.PaymentName}</span><br />
                            <strong>Status Zamówienia:</strong> <span>{order.status}</span>
                            </div>

                             
                            </div>
                            <div className="flex flex-col items-end ml-2">
    
                            <button
                             onClick={() => handleSelectOrder(order)}
                             className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
                              >Wybierz
                            </button>

    {!order.claimed ? (
        <button
       onClick={() => handleClaimOrder(order.orderNumber)} 
   
            className="bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 mt-2 w-full"
        >
            Przejmij zamówienie
        </button>
    ) : (
        <button
    className="bg-red-600 text-white py-2 px-4 rounded-lg cursor-not-allowed mt-3 w-full h-12" 
    disabled
>
    <div style={{ whiteSpace: 'pre-wrap', textAlign: 'center', fontSize: '0.8rem' }}> 
        Zamówienie Przejęte przez<br />
        {order.claimedBy} 
        </div>
</button>

    )}
</div>
</li>
                    ))}
                </ul>
            </div>
        )}

      
        {selectedOrder && (
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-80 bg-[#18181a] p-6 rounded-lg shadow-lg">
                <h2 className="text-lg font-semibold text-fuchsia-300 mb-4">Zmień status zamówienia</h2>
                <label className="block mb-2 text-gray-300">Numer zamówienia: {selectedOrder.orderNumber}</label>
                <label className="block mb-2 text-gray-300">Wybierz status:</label>
                <select
                    value={newStatus}
                    onChange={(e) => setNewStatus(e.target.value)}
                    className="p-2 border border-gray-600 rounded bg-gradient-to-r from-[#242424] to-[#18181a] text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                    <option value="" disabled>Wybierz status</option>
                    <option value="w trakcie realizacji">W trakcie realizacji</option>
                    <option value="zrealizowane">Zrealizowane</option>
                    <option value="zakończone">Zakończone</option>
                    <option value="anulowane">Anulowane</option>
                </select>
                <div className="mt-4 flex justify-end">
                    <button
                        onClick={() => setSelectedOrder(null)} 
                        className="mr-2 text-gray-400 hover:text-gray-300"
                    >
                        Anuluj
                    </button>
                    <button
                        onClick={() => handleUpdateStatus(selectedOrder.nickname, selectedOrder.orderNumber, selectedOrder.orderId)}
                        className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
                    >
                        Zatwierdź
                    </button>
                </div>
            </div>
        )}
    </div>
)}
 */}
 {activeTab === "chat" && (
  <>
    <p>Wkrótce</p>
  </>
)}

                {activeTab === 'orderService' && (
                    <>
                 
                    {showAlert && (
                            <div
                                className="bg-green-600 text-white p-5 rounded-xl shadow-xl mx-auto max-w-[800px] w-full text-center mt-5 mb-5">
                                Zamówienie złożone pomyślnie! Wkrótce zajmiemy się Twoim zgłoszeniem. Oczekuj wiadomości
                                DM
                                na Discordzie.
                            </div>
                        )}
                     

                        <div className="flex items-center justify-center">
                 
                        <div
                                className="relative w-full max-w-md bg-gradient-to-r from-[#18181a] to-[#101010] p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                <h3 className="text-lg text-center font-bold text-gray-300 mb-4">Utwórz zamówienie</h3>
                        

                            {errorMessage && (
                                <div className="text-red-400 mb-3 font-medium">{errorMessage}</div>
                            )}

                            <div className="w-full p-2 space-y-4">
                                <label htmlFor="service" className="block mb-2 text-sm font-medium text-gray-300">
                                    Wybierz usługę
                                </label>
                                <select
                                    id="service"
                                    className="w-full p-3 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gradient-to-r from-[#242424] to-[#18181a] text-white"
                                    value={selectedService ? selectedService.id : ""}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        const selectedService = services.find((service) => service.id === parseInt(selectedValue));
                                        setSelectedService(selectedService);
                                    }}
                                >
                                   <option value="" disabled>Wybierz usługę</option>
                                   {services.map(service => (
                                   <option key={service.id} value={service.id}>{service.name}</option>
                                   ))}
                                  </select>



                                <label htmlFor="Payment" className="block mb-2 text-sm font-medium text-gray-300">
                                    Wybierz Metode Płatności
                                </label>
                                <select
                                    id="Payment"
                                    className="w-full p-3 border border-gray-600 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gradient-to-r from-[#242424] to-[#18181a] text-white"
                                    value={selectedPayment ? selectedPayment.name : ""}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        const selectedPayment = payments.find((payment) => payment.name === selectedValue);
                                
                                        setSelectedPayment(selectedPayment);

                                    }}
                                >
                                   <option value="" disabled>Wybierz metodę płatności</option>
                                    {payments.map((payment, index) => (
                                        <option key={payment.name} value={payment.name}>{payment.name}</option>              
                                     ))}

                                </select>


                                
                            </div>
                            <label htmlFor="service" className="block  mt-5  text-sm font-medium text-gray-300">
                                Opis zamówienia
                            </label>
                            <textarea
                                value={customDescription}
                                onChange={(e) => setCustomDescription(e.target.value)}
                                placeholder="Wpisz opis zamówienia..."
                                className="w-full p-3 border border-gray-600 rounded mt-4 focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gradient-to-r from-[#242424] to-[#18181a] text-white"
                                rows={3}
                            />
                         

                            <div className="flex justify-end mt-6">
                        
                            <button
    onClick={handleOrder}
    className={`w-full text-white font-bold py-2 px-4 rounded transition duration-300 ${isCooldown ? 'bg-red-600 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
    disabled={isCooldown}
>

    {isCooldown ? (
        <div className="flex items-center">
        <svg aria-hidden="true" role="status" class="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
</svg>
            Proszę czekać! Kolejne zamówienie możesz złożyć za 5 minut
        </div>
    ) : (
        'Kliknij, aby złożyć zamówienie'
    )}
</button>
         
                            </div>
                        </div>
                        </div>
                    </>
                )
                }

                {
                    activeTab === 'changePassword' && (
                        <>
                            <div className="flex items-center justify-center">
                                <div
                                    className="relative w-full max-w-md bg-gradient-to-r from-[#18181a] to-[#101010] p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                    <h3 className="text-lg text-center font-bold text-gray-300 mb-4">Zmiana Hasła</h3>
                                    {errorMessage && <p className="text-red-500 text-center mb-4">{errorMessage}</p>}

                                    <div className="mb-4">
                                        <label className="block text-gray-400 mb-2">Stare Hasło:</label>
                                        <input
                                            type="password"
                                            value={oldPassword}
                                            onChange={(e) => setOldPassword(e.target.value)}
                                            className="w-full p-3 border border-gray-600 rounded bg-gradient-to-r from-[#242424] to-[#18181a] text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-gray-400 mb-2">Nowe Hasło:</label>
                                        <input
                                            type="password"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            className="w-full p-3 border border-gray-600 rounded bg-gradient-to-r from-[#242424] to-[#18181a] text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-gray-400 mb-2">Potwierdź Nowe Hasło:</label>
                                        <input
                                            type="password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            className="w-full p-3 border border-gray-600 rounded bg-gradient-to-r from-[#242424] to-[#18181a] text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>

                                    <button
                                        onClick={handleChangePassword}
                                        className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    >
                                        Zmień Hasło
                                    </button>
                                </div>
                            </div>
                        </>
                    )}


                    { activeTab === 'changeEmail' && (
                        <>
                            <div className="flex items-center justify-center">
                                <div
                                    className="relative w-full max-w-md bg-gradient-to-r from-[#18181a] to-[#101010] p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                    <h3 className="text-lg text-center font-bold text-gray-300 mb-4">Zmiana Email</h3>
                                    {errorMessage && <p className="text-red-500 text-center mb-4">{errorMessage}</p>}

                                    <div className="mb-4">
                                        <label className="block text-gray-400 mb-2">Stary Email:</label>
                                        <input
                                            type="email"
                                            value={oldEmail}
                                            onChange={(e) => setOldEmail(e.target.value)}
                                            className="w-full p-3 border border-gray-600 rounded bg-gradient-to-r from-[#242424] to-[#18181a] text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-gray-400 mb-2">Nowy Email:</label>
                                        <input
                                            type="email"
                                            value={newEmail}
                                            onChange={(e) => setNewEmail(e.target.value)}
                                            className="w-full p-3 border border-gray-600 rounded bg-gradient-to-r from-[#242424] to-[#18181a] text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-gray-400 mb-2">Potwierdź Nowy Email:</label>
                                        <input
                                            type="email"
                                            value={confirmEmail}
                                            onChange={(e) => setConfirmEmail(e.target.value)}
                                            className="w-full p-3 border border-gray-600 rounded bg-gradient-to-r from-[#242424] to-[#18181a] text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                    </div>

                                    <button
                                        onClick={handleChangeEmail}
                                        className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    >
                                        Zmień Email
                                    </button>
                                </div>
                            </div>
                        </>
                    )}


     
                    {activeTab === 'OrderStatistics' && (

                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 w-full p-4 scale-[0.65]">
                        {[
        { 
          title: 'Zamówienia: (przejęte)', 
          count: orderStats.przejęte, 
          bg: 'bg-gradient-to-r from-green-500 to-green-700', 
          icon: <UserGroupIcon className="h-6 w-6" />
        },
        { 
          title: 'Zrealizowane zamówienia', 
          count: orderStats.zrealizowane, 
          bg: 'bg-gradient-to-r from-yellow-500 to-yellow-700', 
          icon: <CheckCircleIcon className="h-6 w-6" />
        },
        { 
          title: 'Zamówienia w trakcie realizacji', 
          count: orderStats['w trakcie realizacji'], 
          bg: 'bg-gradient-to-r from-red-500 to-red-700', 
          icon: <ClipboardDocumentIcon className="h-6 w-6" />
        },
        { 
          title: 'Zamówienia zakończone', 
          count: orderStats.zakończone, 
          bg: 'bg-gradient-to-r from-blue-500 to-blue-700', 
          
          icon: <PencilIcon className="h-6 w-6" />
        },
        { 
          title: 'Zamówienia anulowane', 
          count: orderStats.anulowane, 

          bg: 'bg-gradient-to-r from-gray-500 to-gray-700', 
          
          icon: <XCircleIcon className="h-6 w-6" />
        },
        { 
          title: 'Zamówienia (suma)', 
          count: orderStats.totalOrders, 
          bg: 'bg-gradient-to-r from-purple-500 to-purple-900', 
          
          icon: <ExclamationCircleIcon className="h-6 w-6" />
        },
].map(({ title, count, bg, icon }, index) => (
    <div key={index} className={`p-4 rounded-lg shadow-lg ${bg} flex items-center`}>        
    <div className="mr-4">
        {icon}
    </div>
    <div>
    <h3 className="text-lg text-white font-semibold mb-1">{title}</h3>
    <p className="text-3xl text-white font-extrabold">{count}</p>
</div>

        </div>
      ))}
    </div>
)}





                {activeTab === 'roleManagement' && (
                    <>

                        <div className="flex items-center justify-center">
                            <div
                                className="relative w-full max-w-md bg-gradient-to-r from-[#18181a] to-[#101010] p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105">
                                <h3 className="text-lg text-center font-bold text-gray-300 mb-4">Zarządzaj
                                    rolami</h3>
                                {errorMessage &&
                                    <p className="text-red-500 text-center mb-4">{errorMessage}</p>}
                                <div className="mb-4">
                                    <label className="block text-gray-400 mb-2">Wybierz użytkownika:</label>
                                    <select
                                        onChange={(e) => setSelectedUser(e.target.value)}
                                        value={selectedUser || ''}
                                        className="w-full p-3 border border-gray-600 rounded bg-gradient-to-r from-[#242424] to-[#18181a] text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    >
                                        <option value="" disabled>Wybierz użytkownika</option>
                                        {users.map((user) => (
                                            <option key={user.id} value={user.username}>{user.username}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-4">
                                    <label className="block text-gray-400 mb-2">Wybierz rolę:</label>
                                    <select
                                        onChange={(e) => setSelectedRole(e.target.value)}
                                        value={selectedRole || ''}
                                        className="w-full p-3 border border-gray-600 rounded bg-gradient-to-r from-[#242424] to-[#18181a] text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    >
                                        <option value="" disabled>Wybierz rolę</option>
                                        {availableRoles.map((role, index) => (
                                            <option key={index} value={role}>{role}</option>
                                        ))}
                                    </select>
                                </div>
                                <button
                                    onClick={handleRoleChange}
                                    className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                >
                                    Przypisz rolę
                                </button>
                            </div>
                        </div>

                    </>
                )}
            </div>

        </div>

    )

};

export default PanelUserSection;
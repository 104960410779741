// src/components/FAQSection.js
import React, {} from 'react';
import { PhoneIcon, QuestionMarkCircleIcon, UserGroupIcon, ClockIcon } from '@heroicons/react/24/solid';


function FAQSection() {

  const faqs = [
    {
      question: "Jak mogę skontaktować się z zespołem?",
      answer: "Możesz skontaktować się z nami przez nasz serwer Discord lub formularz kontaktowy na stronie.",
      icon: <PhoneIcon className="w-6 h-6 text-green-500 mr-3 mb-3" />, 
    },
    {
      question: "Jakie usługi oferujecie?",
      answer: "Oferujemy: Serwer Discord, Boty Discord, Strona Internetowa, Grafiki, Montaż Filmów, Trailery Minecraft, Animacje, Plugin Minecraft, Paczki Minecraft, Budowa Minecraft.",
      icon: <QuestionMarkCircleIcon className="w-6 h-6 text-blue-500 mr-3 mb-3" />,
    },
    
    {
      question: "Czy mogę dostosować serwer do moich potrzeb?",
      answer: "Tak, wszystkie nasze serwery są dostosowywane według Twoich wymagań i potrzeb.",
      icon: <UserGroupIcon className="w-6 h-6 text-purple-500  mr-3 mb-3" />,
    },
    {
      question: "Jakie są czasy realizacji projektów?",
      answer: "Czasy realizacji zależą od złożoności projektu, jednak zawsze staramy się działać jak najszybciej.",
      icon: <ClockIcon className="w-6 h-6 text-yellow-500 mr-3 mb-3" />,
    },
  ];

  return (

    <section id="faq" className="container mx-auto my-20 text-white" data-aos="fade-up">
    <div className="text-center">
      <h2 className="text-3xl font-bold mb-8">Najczęściej zadawane pytania</h2>
    </div>
  
    <div className="grid gap-6 lg:grid-cols-2 scale-[0.75]">
      {faqs.map((faq, index) => (
        <div
          key={index}
          className="relative bg-[#171719] rounded-3xl p-6 transition-transform transform hover:scale-105 hover:shadow-lg hover:border-2 hover:border-white group"
        >
           {faq.icon}  
  
  
          <h3 className="text-xl font-bold mb-2">{faq.question}</h3>
          <p className="text-sm text-slate-300">{faq.answer}</p>
          <div className="absolute inset-0 rounded-3xl border-2 border-transparent group-hover:border-white transition duration-300" />
        </div>
      ))}
    </div>
  </section>
  
  );
};


export default FAQSection;

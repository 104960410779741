import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import useCheckCurrentPathAndScroll from "../../utils/useCheckCurrentPathAndScroll";
import routes from "../../utils/routes";
import { FaDiscord } from 'react-icons/fa'; 

const PanelSection = () => {
    const checkCurrentPathAndScroll = useCheckCurrentPathAndScroll();
    // const HOST = process.env.REACT_APP_BACKEND_HOST;
    // const [username, setUsername] = useState("");
    // const [password, setPassword] = useState("");
    // const [email, setEmail] = useState("");
    // const [verificationCode, setVerificationCode] = useState("");  
    // const [errorMessage, setErrorMessage] = useState("");
    // const [successMessage, setSuccessMessage] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [captchaPassed, setCaptchaPassed] = useState(false);  
   
   
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);


    const handleLinkClick = (event, targetId, route) => {
        if (event) event.preventDefault();
        checkCurrentPathAndScroll(targetId, route);
    };

    const handleCaptchaChange = (value) => {
        if (value) {
            setCaptchaPassed(true);  
        } else {
            setCaptchaPassed(false);  
        }
    };


    // const handleGenerateVerificationCode = async () => {
    //     if (!username) {
    //         console.log("Brak nazwy użytkownika Discord.");
    //         setErrorMessage('Proszę podać nazwę użytkownika Discord w formularzu login');
    //         return;
    //     }
    
    //     try {
    //         console.log("Wysyłane dane:", { username });  
    
    //         const response = await fetch(`${HOST}/api/generate-verification-code`, {
    //             method: 'POST',
    //             mode: "cors",
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ username }),  
    //         });
    
    //         const data = await response.json();
    
    //         if (response.ok) {
    //             setSuccessMessage('Kod weryfikacyjny został wysłany.');
    //             setErrorMessage('');
    //         } else {
    //             setErrorMessage(data.error || 'Wystąpił problem z generowaniem kodu.');
    //             setSuccessMessage('');
    //         }
    //     } catch (error) {
    //         console.error("Błąd przy wysyłaniu:", error);
    //         setErrorMessage('Wystąpił błąd podczas generowania kodu.');
    //         setSuccessMessage('');
    //     }
    // };
    

 
    

    
    // const handleLogin = async (event) => {
    //     event.preventDefault();

    //     if (!captchaPassed) {
    //         setErrorMessage("Proszę przejść CAPTCHA, aby kontynuować.");
    //         setSuccessMessage("");
    //         return;
    //     }

    //     if (!verificationCode) {
    //         setErrorMessage("Proszę wpisać kod weryfikacyjny.");
    //         return;
    //     }
    //     if (isLoggedIn) {
    //         setErrorMessage('Jesteś już zalogowany.');
    //         setSuccessMessage('');
    //         return;
    //     }

    //     try {
    //         const response = await fetch(`${HOST}/api/login`, {
    //             method: 'POST',
    //             mode: "cors",
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ 
    //                 username, 
    //                 password,
    //                 email,
    //                 verificationCode,

    //             }),
    //         });

    //         if (response.status === 429) {
    //             setErrorMessage('Za dużo prób logowania, spróbuj ponownie później');
    //             setSuccessMessage('');
    //             return; 
    //         }

    //         const data = await response.json();

    //         if (response.ok) {
    //             if (data.token) {
    //                 setIsLoggedIn(true);
    //                 setErrorMessage('');
    //                 setSuccessMessage('Zalogowano pomyślnie!');
    //                 localStorage.setItem('token', data.token);
    //                 localStorage.setItem('username', username);
    //                 handleLinkClick(null, 'dashboard', routes.dashboard);
    //             } else {
    //                 setErrorMessage('Nieprawidłowy login lub hasło');
    //                 setSuccessMessage('');
    //             }
    //         } else {
    //             setErrorMessage(data.error || 'Nieprawidłowy login lub hasło');
    //             setSuccessMessage('');
    //         }
    //     } catch (error) {
    //         setErrorMessage('Wystąpił błąd podczas logowania');
    //         setSuccessMessage('');
    //     }
    // };

    // const handleRegister = async (event) => {
    //     event.preventDefault();

    //     if (!captchaPassed) {
    //         setErrorMessage("Proszę przejść CAPTCHA, aby kontynuować.");
    //         setSuccessMessage("");
    //         return;
    //     }

    //     if (!verificationCode) {
    //         setErrorMessage("Proszę wpisać kod weryfikacyjny.");
    //         return;
    //     }

    //     if (isLoggedIn) {
    //         setErrorMessage('Jesteś już zalogowany.');
    //         setSuccessMessage('');
    //         return;
    //     }

  

    //     try {
    //         const response = await fetch(`${HOST}/api/register`, {
    //             method: 'POST',
    //             mode: "cors",
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ 
    //                 username, 
    //                 password,
    //                 email,
    //                 verificationCode,
    //             }), 
    //         });

    //         const data = await response.json();

    //         if (response.ok) {
             
    //             setIsLoggedIn(true);
    //             setErrorMessage('');
    //             setSuccessMessage(`Rejestracja użytkownika ${username} zakończona pomyślnie! Za chwilę nastąpi automatyczne zalogowanie do panelu.`);
    //             localStorage.setItem('token', data.token);
    //             localStorage.setItem('username', username);
    //             setTimeout(() => {
    //                 handleLinkClick(null, 'dashboard', routes.dashboard);
    //             }, 5000); 
                
    //         } else {
    //             setErrorMessage(data.error || 'Rejestracja nie powiodła się.');
    //             setSuccessMessage('');
    //         }
    //     } catch (error) {
    //         setErrorMessage('Wystąpił błąd podczas rejestracji.');
    //         setSuccessMessage('');
    //     }
    // };


const handleLoginWithDiscord = async () => {
    window.location.href = 'http://api.fazedev.pl/api/auth/discord'; 
};



//     return (
//         <div className="min-h-screen flex items-center justify-center relative bg-custom-panel">
//             <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between lg:space-x-12 p-1 relative">
//                 <div className="w-full lg:w-1/2 flex justify-center lg:justify-end mb-2 lg:mb-2">
//                     <img
//                         src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
//                         className="max-w-full h-auto object-contain"
//                         alt="Logo"
//                     />
//                 </div>
//                 <div className="w-full lg:w-1/2 p-8 mb-24 relative z-10">
//                     <div className="relative bg-zinc-800 p-8 rounded-lg shadow-2xl border border-gray-900">
//                         <h2 className="text-2xl font-bold text-white mb-6 text-center">Panel logowania</h2>
//                         {errorMessage && (
//                             <div className="text-red-500 mb-4 text-center">{errorMessage}</div>
//                         )}
//                         {successMessage && (
//                             <div className="text-green-500 mb-4 text-center">{successMessage}</div>
//                         )}
//                         {!isLoggedIn ? (
//                             <>
//                                 <form onSubmit={handleLogin}>
//                                 <div className="mb-4">
//                                         <label htmlFor="username" className="block text-white mb-2">email</label>
//                                         <input
//                                             type="email"
//                                             id="email"
//                                             value={email}
//                                             onChange={(e) => setEmail(e.target.value)}
//                                             className="w-full p-2 border border-gray-300 rounded"
//                                             required
//                                             autoComplete="email"
//                                         />
//                                     </div>
//                                     <div className="mb-4">
//                                         <label htmlFor="username" className="block text-white mb-2">Login</label>
//                                         <input
//                                             type="text"
//                                             id="username"
//                                             value={username}
//                                             onChange={(e) => setUsername(e.target.value)}
//                                             className="w-full p-2 border border-gray-300 rounded"
//                                             required
//                                             autoComplete="username"
//                                         />
//                                     </div>
//                                     <div className="mb-4">
//                                         <label htmlFor="password" className="block text-white mb-2">Hasło</label>
//                                         <input
//                                             type="password"
//                                             id="password"
//                                             value={password}
//                                             onChange={(e) => setPassword(e.target.value)}
//                                             className="w-full p-2 border border-gray-300 rounded"
//                                             required
//                                             autoComplete="current-password"
//                                         />
//                                         </div>
//                                     <div className="mb-4">
//                                         <label htmlFor="verificationCode" className="block text-white mb-2">Kod weryfikacyjny</label>
//                                         <input
//                                             type="verificationCode"
//                                             id="verificationCode"
//                                             value={verificationCode}
//                                             onChange={(e) => setVerificationCode(e.target.value)}
//                                             className="w-full p-2 border border-gray-300 rounded"
//                                             required
//                                         />

                                   
//                             <button
//                                 type="button"
//                                 onClick={handleGenerateVerificationCode}
//                                 className="mt-4 w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
//                             >
//                                 Wygeneruj kod weryfikacyjny
//                             </button>




//                                     </div>
//                                    <div className="flex justify-center my-4 w-full">
//                                    <ReCAPTCHA
//                                     sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}              
//                                   onChange={handleCaptchaChange}
//                                      />
//                                  </div>
//                                  {!captchaPassed && (
//                                    <>
//                                    <div className="text-green-500 mb-4 text-center">
//                                    Potwierdź, że nie jesteś robotem
//                                    </div>

//                                   <div className="text-green-500 mb-4 text-center">
//                                    Kliknij w pole powyżej, aby zakończyć weryfikację
//                                     </div>
//                                     </>
//                                    )}
  
//                                     <button
//                                         type="submit"
//                                         disabled={!captchaPassed}  
//                                         className={`w-full bg-purple-700 hover:bg-purple-900 text-white font-bold py-2 px-4 rounded-lg transition duration-300 shadow-md ${!captchaPassed ? 'bg-red-500 hover:bg-red-700 cursor-not-allowed opacity-50' : 'bg-green-500 hover:bg-green-700'}`}
//                                     >
//                                         Zaloguj się
//                                     </button>
//                                 </form>
//                                 <div className="mt-4">
//                                     <button
//                                         onClick={handleRegister}
//                                         disabled={!captchaPassed}  
//                                         className={`w-full bg-purple-700 hover:bg-purple-900 text-white font-bold py-2 px-4 rounded-lg transition duration-300 shadow-md  ${!captchaPassed ? 'bg-red-500 hover:bg-red-700 cursor-not-allowed opacity-50' : 'bg-green-500 hover:bg-green-700'}`}
//                                     >
//                                         Zarejestruj się
//                                     </button>
//                                 </div>
//                             </>
//                         ) : (
//                             <div className="mt-4">
//                                 <button
//                                     onClick={(e) => handleLinkClick(e, 'dashboard', routes.dashboard)}
//                                     className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
//                                 >
//                                     Przejdź do panelu
//                                 </button>
//                             </div>
//                         )}
//                         <div className="mt-4">
//                                 <button
//                                       onClick={handleLoginWithDiscord}
//                                       disabled={true}  
//                                     className="w-full text-white font-bold py-2 px-4 rounded-lg transition duration-300 shadow-md  bg-red-500 hover:bg-red-700 cursor-not-allowed opacity-50"
//                                 >
//                                              Zaloguj się przez Discord
//                                 </button>
//                             </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };




return (
    <div className="min-h-screen flex items-center justify-center relative bg-custom-panel">
        <div className="container mx-auto flex flex-col lg:flex-row items-center justify-between lg:space-x-12 p-1 relative">
            <div className="w-full lg:w-1/2 flex justify-center lg:justify-end mb-2 lg:mb-2">
                <img
                    src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
                    className="max-w-full h-auto object-contain"
                    alt="Logo"
                />
            </div>
            <div className="w-full lg:w-1/2 p-8 mb-24 relative z-10">
                <div className="relative bg-zinc-800 p-8 rounded-lg shadow-2xl border border-gray-900">
                    <h2 className="text-2xl font-bold text-white mb-6 text-center">Panel logowania</h2>
            
                    <div className="w-full bg-zinc-900 text-gray-100 border-l-4 border-purple-600 p-4 mb-6">
  <p className="font-bold text-center">
    Aby zalogować się do panelu, musisz mieć konto na Discordzie 
    </p>
    <p className="font-bold text-center">
    oraz być członkiem naszego serwera
    </p>
    <p className="font-bold text-center">
    Jeśli nie masz Discorda, pobierz go i postępuj zgodnie z instrukcją:
    <br />
    </p>
    <div className="text-center">
    <a href="https://discord.com/download" className="text-purple-600 hover:text-purple-700 font-semibold">Pobierz Discord</a> i dołącz do nas na <a href="https://dc.fazedev.pl" className="text-purple-600 hover:text-purple-700 font-semibold">dc.fazedev.pl</a>.
</div>
</div>





                    {!isLoggedIn ? (
                        <>
                            <div className="flex justify-center my-4 w-full">
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}              
                                    onChange={handleCaptchaChange}
                                />
                            </div>
                            {!captchaPassed && (
                                <>
                                    <div className="text-gray-500 mb-4 text-center">
                                        Potwierdź, że nie jesteś robotem
                                    </div>
                                    <div className="text-gray-500 mb-4 text-center">
                                        Kliknij w pole powyżej, aby zakończyć weryfikację
                                    </div>
                                    <div className="text-gray-500 mb-4 text-center">
                                     Jeśli CAPTCHA się nie pojawi, spróbuj odświeżyć stronę lub ponownie wpisz adres w przeglądarke.
                                            </div>
                                </>
                            )}
                            <div className="mt-4">
                                <button
                                    onClick={handleLoginWithDiscord}
                                    disabled={!captchaPassed}  
                                    className={`w-full bg-purple-700 hover:bg-purple-900 text-white font-bold py-2 px-4 rounded-lg transition duration-300 shadow-md ${!captchaPassed ? 'bg-red-500 hover:bg-red-700 cursor-not-allowed opacity-50' : 'bg-green-500 hover:bg-green-700'}`}
                                >
                                <div className="flex items-center justify-center space-x-2">
                                  <FaDiscord 
                                     className={`w-6 h-6 animate-pulse transition-all duration-500`}  
                                />
                                 <span>Zaloguj się przez Discord</span>
                                   </div>
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="mt-4">
                            <button
                                onClick={(e) => handleLinkClick(e, 'dashboard', routes.dashboard)}
                                className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300"
                            >
                                Przejdź do panelu
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
);
};
export default PanelSection;

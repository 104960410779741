
import React from "react";
import ReviewsSection from "../components/main/ReviewsSection";
import FooterDashBoard from "../components/footer/FooterPanelSection";
import NavbarDashboardPanel from "../components/navbar/NavBarDashboardPanel";
import CHAT from "../components/main/RedisSection";

function ReviewsPage () {
    return (
        <main>
         <NavbarDashboardPanel />
      
            <ReviewsSection />
            <FooterDashBoard/>
        </main>
    );
}

export default ReviewsPage ;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admins-hidden {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

}

.admins-visible {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.admins {
      transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;

}
.transition-transform {
  transition: transform 0.3s ease;
}

.rotate-180 {
  transform: rotate(180deg);
}


.admin-count {
  color: #5c14c8; /* Ustaw kolor tekstu na fioletowy */
  font-weight: bold; /* Uczyń tekst pogrubionym, aby był bardziej widoczny */
}


.admins-hidden {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.admins-visible {
    visibility: visible;
    opacity: 1;
      transition: opacity 0.5s ease-in-out;
}

`, "",{"version":3,"sources":["webpack://./src/components/css/admins.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,oCAAoC;;AAExC;;AAEA;IACI,UAAU;IACV,oCAAoC;AACxC;;AAEA;MACM,iEAAiE;;AAEvE;AACA;EACE,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;AAC3B;;;AAGA;EACE,cAAc,EAAE,oCAAoC;EACpD,iBAAiB,EAAE,uDAAuD;AAC5E;;;AAGA;IACI,kBAAkB;IAClB,UAAU;IACV,oCAAoC;AACxC;;AAEA;IACI,mBAAmB;IACnB,UAAU;MACR,oCAAoC;AAC1C","sourcesContent":[".admins-hidden {\r\n    opacity: 0;\r\n    transition: opacity 0.5s ease-in-out;\r\n\r\n}\r\n\r\n.admins-visible {\r\n    opacity: 1;\r\n    transition: opacity 0.5s ease-in-out;\r\n}\r\n\r\n.admins {\r\n      transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;\r\n\r\n}\r\n.transition-transform {\r\n  transition: transform 0.3s ease;\r\n}\r\n\r\n.rotate-180 {\r\n  transform: rotate(180deg);\r\n}\r\n\r\n\r\n.admin-count {\r\n  color: #5c14c8; /* Ustaw kolor tekstu na fioletowy */\r\n  font-weight: bold; /* Uczyń tekst pogrubionym, aby był bardziej widoczny */\r\n}\r\n\r\n\r\n.admins-hidden {\r\n    visibility: hidden;\r\n    opacity: 0;\r\n    transition: opacity 0.5s ease-in-out;\r\n}\r\n\r\n.admins-visible {\r\n    visibility: visible;\r\n    opacity: 1;\r\n      transition: opacity 0.5s ease-in-out;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

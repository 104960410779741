// src/pages/AdminsPage.js
import React from "react";
import BanSection from "../../components/main/BanSection";
import ContactSection from "../../components/main/ContactSection";
import Navbar from "../../components/navbar/NavbarAdminSection";


function BansPage() {
  return (
    <main>
      <Navbar />
      <BanSection />
      <ContactSection />
    </main>
  );
}

export default BansPage;

import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation,useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import './index.css';
import Footer from "./components/footer/FooterSection";
import IndexPage from "./pages/IndexPage";
import AdminsPage from "./pages/admins/AdminsPage";
import BansPage from "./pages/bans/BansPage";
import PanelPage from "./pages/panel/PanelPage";
import "./components/css/admins.css";
import "./components/css/button.css";
import "./components/css/icon.css";
import "./components/css/menunavigation.css";
import "./components/css/partners.css";
import "./components/css/service.css";
import "./components/css/style.css";

import NotFoundSection from "./components/main/NotFoundSection";
import DashboardPage from "./pages/panel/DashboardPage";
import RulesPage from "./pages/RulesPage";
import MainstencePage from "./pages/MainstencePage";
import OpinionsPage from "./pages/ReviewsPage";
import DashboardRedirect from './utils/Redirect';

function App() {

    const allowedIPs = ['37.248.159.49']; 
    const getUserIP = () => {
        return '37.248.159.49';
    };



    
    useEffect(() => {
        AOS.init({duration: 1000});
    }, []);



    const AppContent = () => {
        const navigate = useNavigate();
        const location = useLocation();
        //const isIndexPage = location.pathname === '/';

        const isPanelRoute = ["/panel", "/Mainstence"].some(prefix => location.pathname.startsWith(prefix));
    
        useEffect(() => {
            const userIP = getUserIP(); 
            const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';
            if (isMaintenanceMode && location.pathname === "/" && !allowedIPs.includes(userIP)) {
                navigate("/Mainstence");
            }
        }, [navigate, location.pathname]);
    
      
        
        return (
            <div className='App bg-custom-gradient'>
                <Routes>
                    <Route path="/" element={<IndexPage />} />        
                    <Route path="/admins" element={<AdminsPage />} />
                    <Route path="/bans" element={<BansPage />} />
                    <Route path="*" element={<NotFoundSection />} />
                    <Route path="/panel" element={<PanelPage />} />
                    <Route path="/Mainstence" element={<MainstencePage />} />
                    <Route path="/rules" element={<RulesPage />} />
                    <Route path="/panel/dashboard" element={<><DashboardRedirect /><DashboardPage /></>} />

                    <Route path="/panel/dashboard/reviews" element={<OpinionsPage />} />
                    
                </Routes>
                {!isPanelRoute && <Footer />}

            </div>
        );
    };

    return (
        <React.StrictMode>
            <Router>
                <AppContent />
            </Router>
        </React.StrictMode>
    );
}

export default App;
import React, { useState, useEffect, useMemo } from 'react';


function TeamSection() {
  const [startIndex, setStartIndex] = useState(0);
  const [skillLevels, setSkillLevels] = useState([]);
  const [onlineStatus, setOnlineStatus] = useState({});
  const [adminCount, setAdminCount] = useState(0);

  const teamMembers = useMemo(() => [
    {
      role: "Grafik",
      nick: ".xalfax",
      discordid: "1022847921542135808",
      Githubnick: "xalfax",
      skills: [
        { name: "Photoshop", level: 5 },
        { name: "Illustrator", level: 80 },
        { name: "InDesign", level: 70 },
        { name: "Figma", level: 60 },
      ],
      avatar: "https://i.pinimg.com/736x/34/ea/20/34ea20e0747020c021677987211a6353.jpg",
      iconClass: "fa-solid fa-image graphics-icon group-hover:graphics-hover",
    },

    {
      role: "Bot dev",
      nick: "qrieded",
      discordid: "879092051793293312",
      Githubnick: "qrieded",
      skills: [
        { name: "Node.js", level: 80 },
        { name: "Discord API", level: 85 },
        { name: "MongoDB", level: 75 },
        { name: "Express.js", level: 70 },
      ],
      avatar: "https://i.pinimg.com/736x/34/ea/20/34ea20e0747020c021677987211a6353.jpg",
      iconClass: "fa-solid fa-robot bot-icon group-hover:bot-hover",
    },
    {
      role: "Java Dev",
      nick: "Edmixon",
      discordid: "1239157033274441741",
      Githubnick: "eskabal",
      skills: [
        { name: "HTML5", level: 95 },
        { name: "CSS3", level: 90 },
        { name: "Java", level: 85 },
        { name: "JavaScript", level: 75 },
      ],
      avatar: "https://i.pinimg.com/736x/34/ea/20/34ea20e0747020c021677987211a6353.jpg",
      iconClass: "fa-solid fa-code java-icon group-hover:java-hover",
    },
    {
      role: "Java Dev",
      nick: "aidenowskyy",
      discordid: "263162376076001282",
      Githubnick: "aidenowskyy",
      skills: [
        { name: "HTML5", level: 95 },
        { name: "CSS3", level: 90 },
        { name: "Java", level: 85 },
        { name: "SQL", level: 80 },
      ],
      avatar: "https://i.pinimg.com/736x/34/ea/20/34ea20e0747020c021677987211a6353.jpg",
      iconClass: "fa-solid fa-code java-icon group-hover:java-hover",
    },
    {
      role: "Skript Dev",
      nick: "melonik16",
      discordid: "980211708930953246",
      Githubnick: "melonik16",
      skills: [
        { name: "Skript", level: 95 },
        { name: "JavaScript", level: 70 },
        { name: "MySQL", level: 65 },
        { name: "PHP", level: 60 },
      ],
      avatar: "https://i.pinimg.com/736x/34/ea/20/34ea20e0747020c021677987211a6353.jpg",
      iconClass: "fa-solid fa-paper-plane skript-icon skript-hover:skript-hover",
    },
    {
      role: "Pack Creator",
      nick: "_szymonx_",
      discordid: "1195389648793505805",
      Githubnick: "_szymonx_",
      skills: [
        { name: "Resource Packs", level: 85 },
        { name: "3D Modeling", level: 75 },
        { name: "Texture Design", level: 70 },
        { name: "Blockbench", level: 65 },
      ],
      avatar: "https://i1.sndcdn.com/avatars-000016782950-0rxvnh-t240x240.jpg",
      iconClass: "fa-solid fa-box pack-icon group-hover:pack-hover",
    },
    {
      role: "Budowniczy",
      nick: "riddlema",
      discordid: "1193352766203121790",
      Githubnick: "riddlema",
      skills: [
        { name: "Building", level: 95 },
        { name: "WorldEdit", level: 90 },
        { name: "VoxelSniper", level: 85 },
        { name: "Terraforming", level: 80 },
      ],
      avatar: "https://i.pinimg.com/736x/34/ea/20/34ea20e0747020c021677987211a6353.jpg",
      iconClass: "fa-solid fa-tools building-icon group-hover:building-hover",
    },
    {
      role: "Animator",
      nick: "m1quu",
      discordid: "704176135994605618",
      Githubnick: "m1quu",
      skills: [
        { name: "Animation", level: 85 },
        { name: "Blender", level: 80 },
        { name: "Maya", level: 75 },
        { name: "After Effects", level: 70 },
      ],
      avatar: "https://i.pinimg.com/736x/34/ea/20/34ea20e0747020c021677987211a6353.jpg",
      iconClass: "fa-solid fa-film animation-icon group-hover:animation-hover",
    },
    {
      role: "Montażysta",
      nick: "onekizi",
      discordid: "819970225583685669",
      Githubnick: "onekizi",
      skills: [
        { name: "Video Editing", level: 90 },
        { name: "Premiere Pro", level: 85 },
        { name: "DaVinci Resolve", level: 80 },
        { name: "After Effects", level: 75 },
      ],
      avatar: "https://i.pinimg.com/736x/34/ea/20/34ea20e0747020c021677987211a6353.jpg",
      iconClass: "fa-solid fa-video video-icon group-hover:video-hover",
    },
    {
      role: "Web Dev",
      nick: "pudlaty",
      discordid: "705766496021905418",
      Githubnick: "pudlaty",
      skills: [
        { name: "CSS", level: 90 },
        { name: "Node.js", level: 80 },
        { name: "Express.js", level: 75 },
        { name: "MongoDB", level: 70 },
      ],
      avatar: "https://i.pinimg.com/736x/34/ea/20/34ea20e0747020c021677987211a6353.jpg",
      iconClass: "fa-solid fa-globe website-icon group-hover:website-hover",
    },
  ], []);
  const PORT = process.env.REACT_APP_BACKEND_PORT;
  const HOST = process.env.REACT_APP_BACKEND_HOST;


  useEffect(() => {
    const fetchStatusesAndAdminCount = async () => {
      try {
        let onlineAdminCount = 0;
        let idleAdminCount = 0;
        let dndAdminCount = 0;
        let offlineAdminCount = 0;

        const statusMap = {};

        for (const member of teamMembers) {
          try {
              const response = await fetch(
                  `${HOST}/api/status/${member.discordid}`,
                  {
                      method: 'GET',
                      mode: 'cors', 
                      headers: {
                          'Content-Type': 'application/json',
                      },
                  }
              );
      
              if (!response.ok) {
                  console.log(
                      `Nie udało się pobrać statusu dla członka ${member.discordid}.`
                  );
                  statusMap[member.discordid] = "offline";
                  offlineAdminCount += 1;
                  continue;
              }
      
              const data = await response.json();
              statusMap[member.discordid] = data.status;
      
              switch (data.status) {
                  case "online":
                      onlineAdminCount += 1;
                      break;
                  case "idle":
                      idleAdminCount += 1;
                      break;
                  case "dnd":
                      dndAdminCount += 1;
                      break;
                  default:
                      offlineAdminCount += 1;
                      break;
              }
          } catch (error) {
              console.log(
                  `Błąd podczas pobierania statusu dla członka ${member.discordid}:`,
                  error
              );
              statusMap[member.discordid] = "offline";
              offlineAdminCount += 1;
          }
      }

        setOnlineStatus(statusMap);
        setAdminCount(onlineAdminCount + idleAdminCount + dndAdminCount);

        console.log(`Liczba dostępnych adminów: ${onlineAdminCount}`);
        console.log(`Liczba adminów w trybie "zaraz wracam": ${idleAdminCount}`);
        console.log(`Liczba adminów w trybie "zajęty": ${dndAdminCount}`);
        console.log(`Liczba offline adminów: ${offlineAdminCount}`);
        } catch (error) {
          console.log("Błąd podczas pobierania statusów i liczby adminów:", error);
        }        
    };

    fetchStatusesAndAdminCount();
  }, [teamMembers,HOST,PORT]);


  useEffect(() => {
    const initialSkillLevels = teamMembers.map(member => 
      member.skills.map(skill => ({ name: skill.name, level: skill.level })) 
    );
    setSkillLevels(initialSkillLevels);
  }, [teamMembers,HOST,PORT]);
  

  const handleScrollLeft = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 4);
    }
  };

  const handleScrollRight = () => {
    if (startIndex + 4 < teamMembers.length) {
      setStartIndex(startIndex + 4);
    }
  };

  return (

    <section className="team-section">

      <div className="team-header">
        <h2>Nasz Zespół</h2>
        <h3> Dostępni administratorzy: <span className="admin-count">{adminCount}</span></h3>



      </div>
      <div className="team-grid">
      <button className="scroll-button left" onClick={handleScrollLeft} disabled={startIndex === 0}>
        <span className="arrow-left"><i className="fas fa-arrow-left"></i></span>
    </button>



        {teamMembers.slice(startIndex, startIndex + 4).map((member, index) => (
          <div key={index} className="profile-card">
            <div className="profile-header">
              <img src={member.avatar} alt={member.nick} className="profile-avatar" />
            </div>
            <div className="profile-body">
              <h2 className="profile-role">
                <span className={`icon ${member.iconClass}`}></span>

              </h2>
              <h4 className="profile-name">
              <span className={`status-indicator ${onlineStatus[member.discordid]}`} ></span>  {member.role} {member.nick}
               </h4>

              <div className="profile-stats">

      <a
        href={`https://discord.com/users/${member.discordid}`}
        className="discord-icon"
        target="_blank"
        rel="noopener noreferrer">
        <i className="fa-brands fa-discord"></i>

      </a>
      <a
        href={`https://github.com/${member.Githubnick}`}
        className="github-icon"
        target="_blank"
        rel="noopener noreferrer" >
        <i className="fa-brands fa-github"></i>

      </a>
              </div>
              <div className="profile-skills">

  {member.skills.map((skill, skillIndex) => {
    const animatedSkill = skillLevels[startIndex + index]?.[skillIndex];
    return (
      <div key={skillIndex} className="skill-bar">

        <span className="skill-name">{skill.name}</span>
        <div className="skill-progress">

          <div className="skill-level" style={{ width: `${animatedSkill?.level || 0}%` }}></div>
        </div>

        <div className="skill-percentage">{animatedSkill?.level || 0}%</div>
      </div>
    );
  })}
</div>

            </div>
            
          </div>
          
        ))}

        <button className="scroll-button right" onClick={handleScrollRight} disabled={startIndex + 4 >= teamMembers.length}>
        <span className="arrow-right"><i className="fas fa-arrow-right"></i></span>
        </button>


      </div>
      <div class="arrows">
  <button class="scroll-button left" onClick={handleScrollLeft} disabled={startIndex === 0}>
    <span class="arrows"><i class="fas fa-arrow-left"></i></span>
  </button>
  <button class="scroll-button right" onClick={handleScrollRight} disabled={startIndex + 4 >= teamMembers.length}>
  <span class="arrows"><i class="fas fa-arrow-right"></i></span>
  </button>
</div>

    </section>
  );
}

export default TeamSection;

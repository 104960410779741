const services = [
    { id: 1, name: 'Serwer Discord' },
    { id: 2, name: 'Boty Discord' },
    { id: 3, name: 'Strona Internetowa' },
    { id: 4, name: 'Grafiki' },
    { id: 5, name: 'Montaż Filmów' },
    { id: 6, name: 'Trailery Minecraft' },
    { id: 7, name: 'Animacje' },
    { id: 8, name: 'Plugin Minecraft' },
    { id: 9, name: 'Paczki Minecraft' },
    { id: 10, name: 'Budowlowa Minecraft' }
];

const payments = [
    { name: 'Blik' },
    {  name: 'paysafecard' },
    {  name: 'przelew' },
];

module.exports = { services, payments };
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.service-hidden {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

}

.service-visible {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.service {
      transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;

}

#toggle-Service {


    padding: 12px 24px; /* More padding for a better look */
    border-radius: 30px; /* Rounded button */
    font-weight: bold; /* Bold font */
    border: 2px solid transparent; /* Initial border */
    transition: all 0.3s ease; /* Smooth transitions */
    box-shadow: 0 4px 12px #5c14c8;
}

#toggle-Service:hover {

    transform: scale(1.05); /* Scale up effect */
    border-color: #5c14c8;
}




.custom-margin {
    margin-bottom: 1.25rem;
  }

.element-hidden {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.element-visible {
    visibility: visible;
    opacity: 1;
      transition: opacity 0.5s ease-in-out;
}

`, "",{"version":3,"sources":["webpack://./src/components/css/service.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV,oCAAoC;;AAExC;;AAEA;IACI,UAAU;IACV,oCAAoC;AACxC;;AAEA;MACM,iEAAiE;;AAEvE;;AAEA;;;IAGI,kBAAkB,EAAE,mCAAmC;IACvD,mBAAmB,EAAE,mBAAmB;IACxC,iBAAiB,EAAE,cAAc;IACjC,6BAA6B,EAAE,mBAAmB;IAClD,yBAAyB,EAAE,uBAAuB;IAClD,8BAA8B;AAClC;;AAEA;;IAEI,sBAAsB,EAAE,oBAAoB;IAC5C,qBAAqB;AACzB;;;;;AAKA;IACI,sBAAsB;EACxB;;AAEF;IACI,kBAAkB;IAClB,UAAU;IACV,oCAAoC;AACxC;;AAEA;IACI,mBAAmB;IACnB,UAAU;MACR,oCAAoC;AAC1C","sourcesContent":["\n.service-hidden {\n    opacity: 0;\n    transition: opacity 0.5s ease-in-out;\n\n}\n\n.service-visible {\n    opacity: 1;\n    transition: opacity 0.5s ease-in-out;\n}\n\n.service {\n      transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;\n\n}\n\n#toggle-Service {\n\n\n    padding: 12px 24px; /* More padding for a better look */\n    border-radius: 30px; /* Rounded button */\n    font-weight: bold; /* Bold font */\n    border: 2px solid transparent; /* Initial border */\n    transition: all 0.3s ease; /* Smooth transitions */\n    box-shadow: 0 4px 12px #5c14c8;\n}\n\n#toggle-Service:hover {\n\n    transform: scale(1.05); /* Scale up effect */\n    border-color: #5c14c8;\n}\n\n\n\n\n.custom-margin {\n    margin-bottom: 1.25rem;\n  }\n\n.element-hidden {\n    visibility: hidden;\n    opacity: 0;\n    transition: opacity 0.5s ease-in-out;\n}\n\n.element-visible {\n    visibility: visible;\n    opacity: 1;\n      transition: opacity 0.5s ease-in-out;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

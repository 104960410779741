import React, { useState } from "react";

import useCheckCurrentPathAndScroll from "../../utils/useCheckCurrentPathAndScroll";
import routes from "../../utils/routes";

function NavbarDashboardPanel() {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const checkCurrentPathAndScroll = useCheckCurrentPathAndScroll();

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const handleLinkClick = (event, targetId, route) => {
    event.preventDefault();
    checkCurrentPathAndScroll(targetId, route);
    if (isMobileNavOpen) {
      toggleMobileNav();
    }
  };

  return (
    <div>
      <div className="absolute left-0 right-0 h-[195px] z-0"></div>
      <div className="center absolute w-[300px] h-[300px] blur-[180px] right-[-90px] bottom-[20%] z-0"></div>

      <nav className="bg-sidebar mt-6 max-md:hidden navigation text-white py-6 w-full max-w-[80%] xl:max-w-[60%] mx-auto rounded-3xl text-sm z-10 relative">
        <div className="flex items-center justify-center space-x-5">
          <ul className="flex items-center justify-center space-x-5 font-bold lg:flex text-sm hidden lg:block z-10">
            <li>
              <button
                onClick={(e) => handleLinkClick(e, "slider", routes.home)}
                className="">
                Strona Główna
              </button>
            </li>
          </ul>

          <button
            id="toggleNav"
            onClick={toggleMobileNav}
            className="px-3 py-2 text-[#5c14c8] rounded-lg lg:hidden z-10">
            <span id="toggleText">☰</span>
          </button>
        </div>

        {isMobileNavOpen && (
          <div
            id="mobileNav"
            className="mobile-nav flex flex-col lg:hidden z-10">
            <button
              onClick={(e) => handleLinkClick(e, "slider", routes.home)}
              className="button-strona-glowna ">
              Strona Główna
            </button>
          </div>
        )}
      </nav>
    </div>
  );
}

export default NavbarDashboardPanel;

import React, { useEffect, useState } from 'react';

const BanSection = () => {
    const [bannedMembers, setBannedMembers] = useState([]);
 
    const [error, setError] = useState(null);
    const [showFullList, setShowFullList] = useState(false);
    const guildId = '1163148957556412498';
    const PORT = process.env.REACT_APP_BACKEND_PORT;
    const HOST = process.env.REACT_APP_BACKEND_HOST;

    useEffect(() => {
        const fetchBannedMembers = async () => {
            try {
                const response = await fetch(`${HOST}/api/status/banned/${guildId}`, {
                    method: 'GET',
                    mode: 'cors', 
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch banned members');
                }
                const data = await response.json();
                setBannedMembers(data);
            } catch (err) {
                setError(err.message);
            }
        };
        fetchBannedMembers();
    }, [guildId, HOST, PORT]);
    

    const toggleList = () => {
        setShowFullList(!showFullList);
    };

   
    if (error) {
        return (
            <div className="mt-20 text-center">
                <div className="bg-red-500 text-white p-4 rounded-2xl shadow-md w-full sm:w-11/12 md:w-3/4 lg:w-1/2 xl:w-1/3 mx-auto">
                    <strong className="font-bold">Wystąpił błąd!</strong>
                    <p className="mt-2">Podczas ładowania listy banów wystąpił problem. Spróbuj ponownie później.</p>
                </div>
            </div>
        );
    }
    
    
    
    
    

    const membersToShow = showFullList ? bannedMembers : bannedMembers.slice(0, 4);

    return (
        <div className="ban-list-container">
           <div className="headingtext">LISTA BANÓW
           </div>


            <div className="ban-headings">
                <div className="heading">Nick</div>

                <div className="heading">Powód</div>
                <div className="heading">Wygasa</div>
            </div>
            {membersToShow.map((member) => (
                <div key={member.id} className="ban-entry">
                    <img
                        src={`https://cdn.discordapp.com/avatars/${member.id}/${member.avatar}.png`}
                        alt={`${member.username}'s avatar`}
                        className="ban-avatar"
                    />
                    <div className="ban-content">
                        <div className="ban-username">{member.username}#{member.discriminator}</div>

                        <div className= "ban-reason">{member.reason || 'Działanie na szkode serwera'}</div>
                        <div className="expirationDate-info">
                            {member.expiration ? new Date(member.expiration).toLocaleString() : 'Brak daty wygaśnięcia'}
                        </div>
                    </div>
                </div>
            ))}
            {bannedMembers.length > 4 && (


                <section className="container mx-auto my-20 text-white">
                    <div className="flex justify-center mt-6 w-full">
                        <button
                            id="toggle-Service"
                            className="text-white  p-4 rounded-full ransition-all flex items-center justify-center"
                            onClick={toggleList}
                        >
                            <i id="arrow-icon" className={`fa-solid ${showFullList ? 'fa-angle-up' : 'fa-angle-down'} text-3xl`}></i>
                        </button>
                    </div>
                </section>
            )}
        </div>
    );
};

export default BanSection;

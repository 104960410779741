// src/pages/IndexPage.js
import React from "react";
import Slider from "../components/main/SliderSection";
import ServiceSection from "../components/main/ServiceSection";
import FAQSection from "../components/main/FAQSection";
import ContactSection from "../components/main/ContactSection";

import Navbar from "../components/navbar/NavbarSection";
import CookieSection from "../components/main/CookieSection";



function IndexPage() {
  return (
    <main>
      <Navbar />
      <Slider />
      <CookieSection />

      <ServiceSection />
      <FAQSection />
      <ContactSection />

    </main>
  );
}

export default IndexPage;

// src/pages/IndexPage.js
import React from "react";
import PanelDashBoard from "../../components/panel/PanelDashboardSection";
import FooterDashBoard from "../../components/footer/FooterPanelSection";
//import NavbarDashboardPanel from "../../components/navbar/NavBarDashboardPanel";

function DashboardPage() {
    return (
        <main>
            <div className="bg-custom-gradient">

            <PanelDashBoard/>
                <FooterDashBoard/>
               </div>
        </main>
    );
}

export default DashboardPage;

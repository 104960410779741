
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/NotFound.css'; // Zaktualizowany plik CSS
import routes from '../../utils/routes';

const NotFoundSection = () => {
    const navigate = useNavigate();

    const handleLinkClick = (event, route) => {
        event.preventDefault();
        navigate(route);
    };

    return (
        <div className="not-found-container">
            <h1 className="animate-header">404</h1>
            <h2 className="animate-header">Nie znaleziono strony</h2>
            <p className="my-5 text-sm lg:text-md">
                Przykro nam, ale strona, której szukasz, nie istnieje.
            </p>
            <button 
                onClick={(e) => handleLinkClick(e, routes.home)} 
                className="button-uslugi"
            >
                Powrót
            </button>
        </div>
    );
};

export default NotFoundSection;

import React, { useState, useEffect, useRef } from 'react';
import { checkUserRoles, fetchUsers, roleButtonMapping } from '../api/backendAPI';

const ChatWidget = () => {
    const username = localStorage.getItem('username'); // Pobranie nazwy użytkownika
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [chatWithUser, setChatWithUser] = useState(null);
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);
    const messagesEndRef = useRef(null);
    
    const HOST = process.env.REACT_APP_BACKEND_HOST;  // Zakładam, że masz ten host ustawiony w pliku .env

    // Funkcja subskrybująca wiadomości
    const subscribeToMessages = () => {
        const eventSource = new EventSource(`${HOST}/api/chat/${username}/subscribe`);
        eventSource.onmessage = (event) => {
            const messageData = JSON.parse(event.data);
            setMessages((prevMessages) => [...prevMessages, messageData]);
        };

        eventSource.onerror = () => {
            console.error('Błąd połączenia z serwerem subskrypcji.');
            eventSource.close();
        };

        return () => {
            eventSource.close();
        };
    };

    // Funkcja do pobierania wiadomości czatu
    const fetchMessages = async () => {
        if (!chatWithUser) return;
        try {
            const response = await fetch(`${HOST}/api/chat/${chatWithUser}/messages`);
            if (!response.ok) throw new Error('Błąd przy pobieraniu wiadomości');
            const data = await response.json();
            setMessages(data);
        } catch (error) {
            console.error('Błąd przy pobieraniu wiadomości:', error);
        }
    };

    // Funkcja do pobierania ról użytkownika
    const verifyUserRoles = async () => {
        try {
            const { roles } = await checkUserRoles(username);
            setRoles(roles);
        } catch (error) {
            console.error('Nie udało się sprawdzić ról:', error);
        }
    };

    // Funkcja do wysyłania wiadomości
    const sendMessage = async () => {
        if (!newMessage.trim()) return;
        const messagePayload = {
            sender: username,
            message: newMessage,
        };

        try {
            const response = await fetch(`${HOST}/api/chat/${chatWithUser}/send`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(messagePayload),
            });

            if (response.ok) {
                setNewMessage('');
            } else {
                console.error('Błąd przy wysyłaniu wiadomości');
            }
        } catch (error) {
            console.error('Błąd przy wysyłaniu wiadomości:', error);
        }
    };

    // Funkcja do obsługi wyboru użytkownika
    const handleUserSelection = (selectedUsername) => {
        setChatWithUser(selectedUsername);
        setMessages([]);
    };

    // Funkcja do obsługi zmiany roli i przycisków do wybrania użytkowników
    const buttonsToDisplay = roles.reduce((acc, role) => {
        const normalizedRole = role.toLowerCase();
        const buttons = roleButtonMapping[normalizedRole] || [];
        return [...acc, ...buttons];
    }, []);

    // Funkcja do pobierania użytkowników
    const fetchAllUsers = async () => {
        try {
            const userData = await fetchUsers();
            setUsers(userData);
        } catch (error) {
            console.error('Błąd podczas pobierania użytkowników:', error);
        }
    };

    useEffect(() => {
        fetchMessages();
        const unsubscribe = subscribeToMessages();
        verifyUserRoles();
        fetchAllUsers();
        return unsubscribe;
    }, [chatWithUser]);

    // Scrollowanie do najnowszej wiadomości
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    return (
        <div className="chat-widget-container p-4 bg-gray-800">
            {/* Czat z wybranym użytkownikiem */}
            {chatWithUser && (
                <div className="chat-window bg-gray-900 p-4 rounded-lg shadow-md max-w-xl mx-auto">
                    <h2 className="text-2xl text-white mb-4">Czat z {chatWithUser}</h2>
                    <div className="messages space-y-4 max-h-64 overflow-y-auto bg-gray-700 p-4 rounded-md">
                        {messages.length > 0 ? (
                            messages.map((msg, index) => (
                                <div key={index} className={`message ${msg.role === 'admin' ? 'bg-green-500' : 'bg-blue-500'} p-2 rounded-lg text-white`}>
                                    <strong>{msg.username} ({msg.role}):</strong> {msg.message}
                                </div>
                            ))
                        ) : (
                            <p className="text-white">Brak wiadomości do wyświetlenia</p>
                        )}
                    </div>
                    <div ref={messagesEndRef} />
                    <div className="message-input mt-4 flex">
                        <input
                            type="text"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            placeholder="Napisz wiadomość..."
                            className="flex-grow p-2 rounded-lg border border-gray-300 focus:outline-none"
                        />
                        <button
                            onClick={sendMessage}
                            className="ml-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                        >
                            Wyślij
                        </button>
                    </div>
                </div>
            )}

            {/* Wybór użytkownika */}
            {!chatWithUser && (
                <div className="user-selection bg-gray-900 p-4 rounded-lg shadow-md max-w-xl mx-auto mt-8">
                    <h2 className="text-2xl text-white mb-4">Wybierz użytkownika do czatu</h2>
                    {users.length > 0 ? (
                        users.map((user) => (
                            <button
                                key={user.username}
                                onClick={() => handleUserSelection(user.username)}
                                className="block w-full bg-blue-500 text-white p-2 rounded-lg mb-2 hover:bg-blue-600"
                            >
                                {user.username}
                            </button>
                        ))
                    ) : (
                        <p className="text-white">Brak użytkowników do wybrania</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default ChatWidget;
